import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useNumericOnly } from '../../../Hooks/useNumericOnly';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton';
import { DegreeType } from '../../../Utils/Enums/Enum';

interface IDetailProps {
    formik: any;
    index: number;
    degrees: any;
}

const Detail: React.FunctionComponent<IDetailProps> = ({ formik, index, degrees }) => {
    const toast = useRef<Toast>(null);

    let otherID = DegreeType.Other.toString();

    const options = ['Yes', 'No'];

    const [isVisible, setIsVisible] = useState(formik.values.academicDetailInfo[index].isVisible);

    const deleteDetail = (e: any) => {
        e.preventDefault();

        const cnt = formik.values.academicDetailInfo.filter((x: any) => x.isVisible).length;

        if (cnt > 1) {
            formik.values.academicDetailInfo[index].isVisible = false;
            setIsVisible(false);
        } else {
            toast.current?.show({ severity: 'warn', detail: 'At least one Academic Detail is required', life: 3000 });
        }
    };

    useEffect(() => {
        formik.values.academicDetailInfo[index].startDate = formik.values.academicDetailInfo[index].startDateString !== '' ? new Date(formik.values.academicDetailInfo[index].startDateString) : new Date('12/31/9999');

        if (formik.values.academicDetailInfo[index].graduationDateString !== '') {
            formik.values.academicDetailInfo[index].graduationDate = new Date(formik.values.academicDetailInfo[index].graduationDateString);
        }
    }, [formik.values.academicDetailInfo[index]]);

    const [loaded, setLoaded] = useState(false);
    const [displayOther, setDisplayOther] = useState(false);
    useEffect(() => {
        if (formik.values.academicDetailInfo[index].degreeID === otherID) {
            setDisplayOther(true);
        } else if (otherID !== '') {
            if (loaded) {
                formik.values.academicDetailInfo[index].degreeName = '';
                degreeList = getDegreeOptions(degrees, 'Other');
                setDegreeOptions(degreeList);
            }
            setLoaded(true);
        }
    }, [formik.values.academicDetailInfo[index].degreeID]);

    const onHide = () => {
        formik.values.academicDetailInfo[index].degreeID = 0;
        formik.values.academicDetailInfo[index].degreeName = '';
        setDegreeName('');
        setDisplayOther(false);
    }

    const [otherDegreeName, setOtherDegreeName] = useState(formik.values.academicDetailInfo[index].degreeName ?? '');

    const addOtherDegree = (e: any) => {
        e.preventDefault();

        if (otherDegreeName.trim() === '') {
            toast.current?.show({ severity: 'error', detail: 'Degree cannot be blank', life: 3000 });
            return;
        }

        let isDuplicate = degrees.filter((x: any) => x.name.toLowerCase() === otherDegreeName.toLowerCase().trim());

        if (isDuplicate.length > 0) {
            toast.current?.show({ severity: 'error', detail: `Degree ${otherDegreeName} already exists`, life: 3000 });
        } else {
            setDegreeName(otherDegreeName);
            formik.values.academicDetailInfo[index].degreeName = otherDegreeName;
            setDisplayOther(false);

            degreeList = getDegreeOptions(degrees, otherDegreeName);
            setDegreeOptions(degreeList);
        }
    }


    //const [degree, setDegree] = useState(0);
    const [degreeName, setDegreeName] = useState('');

    const handleOnChange = (e: any) => {
        let id = parseInt(e.target.id);
        let name = e.target.innerText;

        formik.values.academicDetailInfo[index].degreeID = id;

        //setDegree(id);
        setDegreeName(name);
        setOtherDegreeName('');
    }







    const degreeNameChange = (e: any) => {
        setOtherDegreeName(e.target.value);
        e.preventDefault();
    }

    const getDegreeOptions = (degrees: any[], otherDegreeName: string) => {
        return degrees.map((x: any) => {
            const name = x.name.toLowerCase() !== 'other' ? x.name : otherDegreeName !== '' ? otherDegreeName : x.name;
            return (
                <option key={x.degreeID} value={x.degreeID}>{name}</option>
            )
        }, this);

    }

    let degreeList = getDegreeOptions(degrees, otherDegreeName);

    const [degreeOptions, setDegreeOptions] = useState(degreeList);


    //useEffect(() => {
    //    if (formik.values.academicDetailInfo.filter((x: any) => (x.isVisible === true || x.isVisible === 'true') && x.highestLevelDegree === true || x.highestLevelDegree === 'Yes').length > 1) {
    //        const academicDetailID = formik.values.academicDetailInfo[index].academicDetailInfo;
    //        const hld = (formik.values.academicDetailInfo[index].highestLevelDegree === true || formik.values.academicDetailInfo[index].highestLevelDegree === 'Yes') ? 'Yes' : 'No';
    //        //console.log('XXXXXXXXXXXX', hld, formik.values.academicDetailInfo.filter((x: any) => x.academicDetailID !== formik.values.academicDetailInfo[index].academicDetailID && (x.isVisible === true || x.isVisible === 'true')));
    //        console.log('XXXXXXXXXXXX', hld, academicDetailID);

    //        formik.values.academicDetailInfo.filter((x: any) => x.academicDetailID !== academicDetailID && (x.isVisible === true || x.isVisible === 'true')).map((x: any) => {
    //            x.highestLevelDegree = hld === 'Yes' ? 'No' : 'Yes';
    //        });

    //        //formik.values.academicDetailInfo.filter((x: any) => (x.isVisible === true || x.isVisible === 'true')).map((x: any) => {
    //        //    if (x.academicDetailID !== academicDetailID) {
    //        //        x.highestLevelDegree = hld === 'Yes' ? 'No' : 'Yes';
    //        //    }
    //        //});
    //    }
    //}, [formik.values.academicDetailInfo[index].highestLevelDegree]);


    return (
        <>
            <Toast ref={toast} />

            <Dialog header="Other Degree" visible={displayOther} style={{ width: '25vw', backgroundColor: 'red' }} onHide={onHide}>
                <div className="row">
                    <div className="col-sm-9">
                        <input type="text" className="form-control" value={otherDegreeName ?? ""} maxLength={50} onChange={degreeNameChange} />
                    </div>
                    <div className="col-sm-2">
                        <button type="button" className="btn btn-primary" disabled={otherDegreeName === ''} onClick={addOtherDegree}>Add</button>
                    </div>
                </div>
            </Dialog>

            {isVisible && (
                <>
                    <div className="form-section mb-2">

                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4">

                                <div className="form-group">
                                    <label className="mandatory" htmlFor={"academicDetailInfo[" + index + "].institution"}>College/University</label>
                                    <input
                                        id={"academicDetailInfo[" + index + "].institution"}
                                        name={"academicDetailInfo[" + index + "].institution"}
                                        type="text"
                                        maxLength={100}
                                        className={formik.touched.academicDetailInfo &&
                                            formik.touched.academicDetailInfo[index] &&
                                            formik.touched.academicDetailInfo[index].institution &&
                                            formik.errors.detailErrors &&
                                            formik.errors.detailErrors[index] &&
                                            formik.errors.detailErrors[index].institution
                                            ? 'form-control form-control-validation-error' : 'form-control'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={(formik.values.academicDetailInfo[index].institution) ?? ""}
                                    />


                                    {formik.touched.academicDetailInfo &&
                                        formik.touched.academicDetailInfo[index] &&
                                        formik.touched.academicDetailInfo[index].institution &&
                                        formik.errors.detailErrors &&
                                        formik.errors.detailErrors[index] &&
                                        formik.errors.detailErrors[index].institution ? (
                                        <div>{formik.errors.detailErrors[index].institution}</div>
                                    ) : null}
                                </div>

                            </div>





                            <div className="col-xl-3 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label className="mandatory" htmlFor={"academicDetailInfo[" + index + "].degreeID"}>Degree</label>
                                    <select
                                        id={"academicDetailInfo[" + index + "].degreeID"}
                                        name={"academicDetailInfo[" + index + "].degreeID"}
                                        className={formik.touched.academicDetailInfo &&
                                            formik.touched.academicDetailInfo[index] &&
                                            formik.touched.academicDetailInfo[index].degreeID &&
                                            formik.errors.detailErrors &&
                                            formik.errors.detailErrors[index] &&
                                            formik.errors.detailErrors[index].degreeID
                                            ? 'form-control form-control-validation-error' : 'form-control'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={(formik.values.academicDetailInfo && formik.values.academicDetailInfo[index] && formik.values.academicDetailInfo[index].degreeID) ?? 0}
                                    >
                                        <option key={DegreeType.None} value={DegreeType.None}></option>
                                        {degreeOptions}
                                    </select>
                                    {formik.touched.academicDetailInfo &&
                                        formik.touched.academicDetailInfo[index] &&
                                        formik.touched.academicDetailInfo[index].degreeID &&
                                        formik.errors.detailErrors &&
                                        formik.errors.detailErrors[index] &&
                                        formik.errors.detailErrors[index].degreeID ? (
                                        <div>{formik.errors.detailErrors[index].degreeID}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label className="mandatory" htmlFor={"academicDetailInfo[" + index + "].fieldOfStudy"}>Major or Field of Study</label>
                                    <input
                                        id={"academicDetailInfo[" + index + "].fieldOfStudy"}
                                        name={"academicDetailInfo[" + index + "].fieldOfStudy"}
                                        type="text"
                                        maxLength={100}
                                        className={formik.touched.academicDetailInfo &&
                                            formik.touched.academicDetailInfo[index] &&
                                            formik.touched.academicDetailInfo[index].fieldOfStudy &&
                                            formik.errors.detailErrors &&
                                            formik.errors.detailErrors[index] &&
                                            formik.errors.detailErrors[index].fieldOfStudy
                                            ? 'form-control form-control-validation-error' : 'form-control'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={(formik.values.academicDetailInfo[index].fieldOfStudy) ?? ""}
                                    />
                                    {formik.touched.academicDetailInfo &&
                                        formik.touched.academicDetailInfo[index] &&
                                        formik.touched.academicDetailInfo[index].fieldOfStudy &&
                                        formik.errors.detailErrors &&
                                        formik.errors.detailErrors[index] &&
                                        formik.errors.detailErrors[index].fieldOfStudy ? (
                                        <div>{formik.errors.detailErrors[index].fieldOfStudy}</div>
                                    ) : null}
                                </div>
                            </div>



                            <div className="col-xl-3 col-lg-3 col-md-4">
                                <div className="form-group">
                                    <label className="mandatory" htmlFor={"academicDetailInfo[" + index + "].highestLevelDegree"}>Highest Level Degree</label>
                                    <div>
                                        <SelectButton
                                            id={"academicDetailInfo[" + index + "].highestLevelDegree"}
                                            value={(formik.values.academicDetailInfo[index].highestLevelDegree === false || formik.values.academicDetailInfo[index].highestLevelDegree === "No") ? "No" : "Yes"}
                                            onChange={formik.handleChange}
                                            options={options}
                                        />
                                    </div>
                                    {formik.touched.academicDetailInfo &&
                                        formik.touched.academicDetailInfo[index] &&
                                        formik.touched.academicDetailInfo[index].highestLevelDegree &&
                                        formik.errors.detailErrors &&
                                        formik.errors.detailErrors[index] &&
                                        formik.errors.detailErrors[index].highestLevelDegree ? (
                                        <div>{formik.errors.detailErrors[index].highestLevelDegree}</div>
                                    ) : null}
                                </div>
                            </div>




                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-8">
                                <div className="form-group">
                                    <label htmlFor={"academicDetailInfo[" + index + "].thesis"}>Thesis/Dissertation (optional)</label>
                                    <input
                                        id={"academicDetailInfo[" + index + "].thesis"}
                                        name={"academicDetailInfo[" + index + "].thesis"}
                                        type="text"
                                        maxLength={200}
                                        className="form-control"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={(formik.values.academicDetailInfo[index].thesis) ?? ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label className="mandatory" htmlFor={"academicDetailInfo[" + index + "].currentCumelativeGPA"}>Current Cumulative GPA</label>
                                    <input
                                        id={"academicDetailInfo[" + index + "].currentCumelativeGPA"}
                                        name={"academicDetailInfo[" + index + "].currentCumelativeGPA"}
                                        type="text"
                                        maxLength={4}
                                        className={formik.touched.academicDetailInfo &&
                                            formik.touched.academicDetailInfo[index] &&
                                            formik.touched.academicDetailInfo[index].currentCumelativeGPA &&
                                            formik.errors.detailErrors &&
                                            formik.errors.detailErrors[index] &&
                                            formik.errors.detailErrors[index].currentCumelativeGPA
                                            ? 'form-control form-control-validation-error' : 'form-control'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        onKeyDown={useNumericOnly}
                                        value={(formik.values.academicDetailInfo[index].currentCumelativeGPA) ?? ""}
                                    />
                                    {formik.touched.academicDetailInfo &&
                                        formik.touched.academicDetailInfo[index] &&
                                        formik.touched.academicDetailInfo[index].currentCumelativeGPA &&
                                        formik.errors.detailErrors &&
                                        formik.errors.detailErrors[index] &&
                                        formik.errors.detailErrors[index].currentCumelativeGPA ? (
                                        <div>{formik.errors.detailErrors[index].currentCumelativeGPA}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label className="mandatory" htmlFor={"academicDetailInfo[" + index + "].gpa"}>GPA Scale (Maximum possible)</label>
                                    <input
                                        id={"academicDetailInfo[" + index + "].gpa"}
                                        name={"academicDetailInfo[" + index + "].gpa"}
                                        type="text"
                                        maxLength={4}
                                        className={formik.touched.academicDetailInfo &&
                                            formik.touched.academicDetailInfo[index] &&
                                            formik.touched.academicDetailInfo[index].gpa &&
                                            formik.errors.detailErrors &&
                                            formik.errors.detailErrors[index] &&
                                            formik.errors.detailErrors[index].gpa
                                            ? 'form-control form-control-validation-error' : 'form-control'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        onKeyDown={useNumericOnly}
                                        value={(formik.values.academicDetailInfo[index].gpa) ?? ""}
                                    />
                                    {formik.touched.academicDetailInfo &&
                                        formik.touched.academicDetailInfo[index] &&
                                        formik.touched.academicDetailInfo[index].gpa &&
                                        formik.errors.detailErrors &&
                                        formik.errors.detailErrors[index] &&
                                        formik.errors.detailErrors[index].gpa ? (
                                        <div>{formik.errors.detailErrors[index].gpa}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label htmlFor={"academicDetailInfo[" + index + "].startDateString"}>Start Date (optional)</label>
                                    <input
                                        id={"academicDetailInfo[" + index + "].startDateString"}
                                        name={"academicDetailInfo[" + index + "].startDateString"}
                                        type="date"
                                        className={formik.touched.academicDetailInfo &&
                                            formik.touched.academicDetailInfo[index] &&
                                            formik.touched.academicDetailInfo[index].startDateString &&
                                            formik.errors.detailErrors &&
                                            formik.errors.detailErrors[index] &&
                                            formik.errors.detailErrors[index].startDateString
                                            ? 'form-control form-control-validation-error' : 'form-control'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={(formik.values.academicDetailInfo[index].startDateString) ?? ""}
                                    />
                                    {formik.touched.academicDetailInfo &&
                                        formik.touched.academicDetailInfo[index] &&
                                        formik.touched.academicDetailInfo[index].startDateString &&
                                        formik.errors.detailErrors &&
                                        formik.errors.detailErrors[index] &&
                                        formik.errors.detailErrors[index].startDateString ? (
                                        <div>{formik.errors.detailErrors[index].startDateString}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4">
                                <div className="form-group">
                                    <label className="mandatory" htmlFor={"academicDetailInfo[" + index + "].graduationDateString"}>Graduation Date/Anticipated Date</label>
                                    <input
                                        id={"academicDetailInfo[" + index + "].graduationDateString"}
                                        name={"academicDetailInfo[" + index + "].graduationDateString"}
                                        type="date"
                                        className={formik.touched.academicDetailInfo &&
                                            formik.touched.academicDetailInfo[index] &&
                                            formik.touched.academicDetailInfo[index].graduationDateString &&
                                            formik.errors.detailErrors &&
                                            formik.errors.detailErrors[index] &&
                                            formik.errors.detailErrors[index].graduationDateString
                                            ? 'form-control form-control-validation-error' : 'form-control'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={(formik.values.academicDetailInfo[index].graduationDateString) ?? ""}
                                    />
                                    {formik.touched.academicDetailInfo &&
                                        formik.touched.academicDetailInfo[index] &&
                                        formik.touched.academicDetailInfo[index].graduationDateString &&
                                        formik.errors.detailErrors &&
                                        formik.errors.detailErrors[index] &&
                                        formik.errors.detailErrors[index].graduationDateString ? (
                                        <div>{formik.errors.detailErrors[index].graduationDateString}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-8">
                                <button id={`removeButton${index}`} className="btn btn-secondary btn-sm" onClick={deleteDetail}>Remove</button>
                            </div>
                        </div>

                    </div>
                </>
            )}
        </>
    );
};

export default Detail;