export const getDateMMddYYY = (date?: string) => {
    if (date) {
        const d = new Date(date);
        const month = d.getMonth() + 1;
        return month + "/" + d.getDate() + "/" + d.getFullYear();
    }
    return '';
}

export const getDateFormatMMddYYY = (date?: Date) => {
    if (date) {
        const d = new Date(date);

        return d.toLocaleDateString();
    }
    return '';
}