import * as React from 'react';
import { useContext } from 'react';
import { SessionContext } from '../../Context/Session/SessionContext';
import Dashboard from '../Dashboard/Dashboard';
import Profile from '../Profile/Profile/Profile';

interface IContainerProps {
}

const Container: React.FunctionComponent<IContainerProps> = (props) => {
    const { session } = useContext(SessionContext);

    return (
        <>
            {session.isAuthenticated && !session.hasApplicants && (
                <Profile />
            )}
            {session.isAuthenticated && session.hasApplicants && (
                <Dashboard />
            )}
        </>
    );
};

export default Container;