import * as React from 'react';

interface IWelcomeLanguageProps {
    displayIntro: boolean;
    displayInfo: boolean;
}

const WelcomeLanguage: React.FunctionComponent<IWelcomeLanguageProps> = ({ displayIntro, displayInfo }) => {
    return (
        <>
            {displayIntro && (
                <p>
                    The National Institute of Allergy and Infectious Diseases (NIAID) conducts and supports basic and applied research to better understand, treat, and ultimately prevent infectious, immunologic, and allergic diseases. Among the 27 Institutes and Centers that comprise the National Institutes of Health, NIAID has a unique mandate, which requires the Institute to respond to emerging public health threats. Toward this end, NIAID manages a complex and diverse research portfolio that aims to do the following:
                    <ul>
                        <li>
                            Expand the breadth and depth of knowledge in all areas of infectious, immunologic, and allergic diseases
                        </li>
                        <li>
                            Develop flexible domestic and international research capacities to respond appropriately to emerging and re-emerging disease threats at home and abroad
                        </li>
                    </ul>
                    NIAID advances the understanding, diagnosis, and treatment of many of the world’s most intractable and widespread diseases. Key research areas include newly emerging and re-emerging infectious diseases such as tuberculosis and influenza, HIV/AIDS, biodefense, and immune-mediated diseases including asthma and allergy.
                </p>
            )}
            {displayInfo && (
                <>
                    <p>
                        The NIAID Postdoc Application System allows interested postdoctoral candidates to submit application materials for <a href="https://www.niaid.nih.gov/research/research-conducted-niaid" target="_blank">intramural NIAID principal investigators (PIs)</a> to search and view - PI's can then contact potential candidates for their lab. Interested candidates are encouraged to post their CVs/resumes as well as reach out to individual investigators. To see fellowship eligibility requirements click <a href="https://www.niaid.nih.gov/about/postdoctoral-research-training" target="_blank">here</a>.
                    </p>

                    Your information will be kept on file for twelve months from the date of submission. If an investigator is interested in your credentials/qualifications, you will be contacted and invited to discuss a possible position. If you accept another position, or are no longer interested in a postdoctoral position in NIAID, please contact <a href="mailto:NIAIDTraining@nih.gov">NIAIDTraining@nih.gov</a>&nbsp;to have your application withdrawn from the system.
                </>
            )}
        </>
    );
};

export default WelcomeLanguage;