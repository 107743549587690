import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import useUploadDocument from '../../../Hooks/useUploadDocument';
import useReplaceDocument from '../../../Hooks/useReplaceDocument';
import DocumentUploadInfo from '../../../Entities/DocumentUploadInfo';
import { DocumentType } from '../../../Utils/Enums/Enum';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import useDeleteDocument from '../../../Hooks/useDeleteDocument';
import BackDrop from '../../Common/BackDrop';

const FormData = require('form-data');

interface IDocumentsProps {
    formik: any;
}

const Documents: React.FunctionComponent<IDocumentsProps> = ({ formik }) => {
    const toast = useRef<Toast>(null);
    const applicantID = formik.values.applicantID;
    const applicationID = formik.values.applicationInfo && formik.values.applicationInfo.applicationID;
    let documentUploadInfo = { documentID: 0, applicantID: applicantID, applicationID: applicationID, s3DocumentID: '', fileName: '', documentTypeID: 0 };
    let sourceButton = '';
    let documentID = 0;

    const [hasResume, setHasResume] = useState(formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Resume).length > 0);
    const [hasCoverLetter, setHasCoverLetter] = useState(formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.CoverLetter).length > 0);

    const [resumeFileName, setResumeFileName] = useState('');
    const [coverLetterFileName, setCoverLetterFileName] = useState('');

    const [uploadDocument, isSending, uploadedDocument] = useUploadDocument();
    const [replaceDocument, isReplaceSending, uploadedReplacedDocument] = useReplaceDocument();
    const [deleteDocument, isDeleteSending, deleteDocumentID] = useDeleteDocument();
    const [isUpLoading, setIsUpLoading] = useState(false);

    const [additionalDocs, setAdditionalDocs] = useState<DocumentUploadInfo[]>([]);

    let selectDeleteFile: DocumentUploadInfo;

    const setDocumentNames = () => {
        let newResumeFileName = formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Resume).length > 0;
        //console.log(newResumeFileName);
        if (newResumeFileName) {
            setResumeFileName(formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Resume)[0].fileName);
        }

        let newCoverLetterFileName = formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.CoverLetter).length > 0;
        //console.log(newCoverLetterFileName);
        if (newCoverLetterFileName) {
            setCoverLetterFileName(formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.CoverLetter)[0].fileName);
        }
    };

    const uploadFileButtonHandler = (e: any) => {
        e.preventDefault();
        const targetId = e.target.id;

        if (isNaN(targetId)) {
            sourceButton = targetId;
            documentID = 0;
        } else {
            sourceButton = '';
            documentID = parseInt(targetId);
        }

        //console.log('targetId', targetId, sourceButton, documentID);

        const element = document.getElementById('inputFile') as HTMLElement;
        element.click();
    }

    const uploadFileHandler = (e: any) => {
        e.preventDefault();

        if (e.target.files && e.target.files.length) {
            const file = e.target.files[0];

            //console.log(file);

            //check file type
            if (!(file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
                toast.current?.show({ severity: 'error', detail: 'Invalid file type, only pdf or Microsoft Word files are permitted', life: 4000 });
                return;
            }

            //check file size
            if (file.size === 0) {
                toast.current?.show({ severity: 'error', detail: 'File is empty', life: 4000 });
                return;
            }

            //check file size
            if (file.size > 104857600) {
                toast.current?.show({ severity: 'error', detail: 'File is too large', life: 4000 });
                return;
            }

            let documentTypeID = sourceButton === 'Resume' ? DocumentType.Resume : sourceButton === 'CoverLetter' ? DocumentType.CoverLetter : DocumentType.Additional;

            documentUploadInfo = { documentID: documentID, applicantID: applicantID, applicationID: applicationID, s3DocumentID: '', fileName: file.name, documentTypeID: documentTypeID };

            //console.log(documentUploadInfo);
            const form = new FormData();

            form.append(JSON.stringify(documentUploadInfo), file, file.name);

            setIsUpLoading(true);
            if (documentID === 0) {
                uploadDocument(documentUploadInfo, form, toast);
            } else {
                replaceDocument(documentUploadInfo, form, toast);
            }
        }
    }

    useEffect(() => {
        if (formik.values.documentInfo !== undefined) {
            const item = {
                documentID: uploadedDocument.documentID,
                applicationID: applicationID,
                s3DocumentID: uploadedDocument.s3DocumentID,
                fileName: uploadedDocument.fileName,
                documentTypeID: uploadedDocument.documentTypeID
            };
            formik.values.documentInfo.push(item);
            //console.log('uploadedDocument: ', uploadedDocument);
            setDocumentNames();

            if (uploadedDocument.documentTypeID === DocumentType.Resume) {
                setHasResume(formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Resume).length > 0);
            }

            if (uploadedDocument.documentTypeID === DocumentType.CoverLetter) {
                setHasCoverLetter(formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.CoverLetter).length > 0);
            }

            if (uploadedDocument.documentTypeID === DocumentType.Additional) {
                setAdditionalDocs(formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Additional));
            }
        }

        setIsUpLoading(false);
    }, [uploadedDocument])

    useEffect(() => {
        if (formik.values.documentInfo && formik.values.documentInfo.length > 0) {
            let renameDoc = formik.values.documentInfo.filter((x: any) => x.documentID === uploadedReplacedDocument.documentID);

            if (renameDoc.length > 0) {
                formik.values.documentInfo.filter((x: any) => x.documentID === uploadedReplacedDocument.documentID)[0].fileName = uploadedReplacedDocument.fileName;

                if (renameDoc[0].documentTypeID === DocumentType.Resume) {
                    setResumeFileName(uploadedReplacedDocument.fileName);
                } else if (renameDoc[0].documentTypeID === DocumentType.CoverLetter) {
                    setCoverLetterFileName(uploadedReplacedDocument.fileName);
                }
            }
        }

        setIsUpLoading(false);
    }, [uploadedReplacedDocument])

    useEffect(() => {
        setHasResume(formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Resume).length > 0);
        setHasCoverLetter(formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.CoverLetter).length > 0);

        setDocumentNames();

        setAdditionalDocs(formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Additional));

    }, [formik.values.documentInfo])

    useEffect(() => {
        if (formik.values.documentInfo && formik.values.documentInfo.length > 0) {

            let docToDelete = formik.values.documentInfo.filter((x: any) => x.documentID === deleteDocumentID);
            if (docToDelete.length > 0) {
                const delFileIndex = formik.values.documentInfo.indexOf(docToDelete[0]);
                formik.values.documentInfo.splice(delFileIndex, 1);

                if (docToDelete[0].documentTypeID === DocumentType.Additional) {
                    setAdditionalDocs(formik.values.documentInfo && formik.values.documentInfo.length > 0 && formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Additional));
                } else if (docToDelete[0].documentTypeID === DocumentType.Resume) {
                    setHasResume(false);
                    setResumeFileName('');
                } else if (docToDelete[0].documentTypeID === DocumentType.CoverLetter) {
                    setHasCoverLetter(false);
                    setCoverLetterFileName('');
                }
            }
        }
    }, [deleteDocumentID])

    const deleteFileButtonHandler = (e: any) => {
        e.preventDefault();
        const targetId = parseInt(e.target.id);
        selectDeleteFile = { documentID: targetId, applicantID: applicantID, applicationID: applicationID, s3DocumentID: '', fileName: '', documentTypeID: 0 };

        confirmPopup({
            target: e.currentTarget,
            message: 'Remove this document?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    }

    const accept = () => {
        deleteDocument(selectDeleteFile.documentID, toast);
    }

    const reject = () => { };

    const confirmDeleteFile = (e: any, document: any) => {
        e.preventDefault();
        selectDeleteFile = document;

        confirmPopup({
            target: e.currentTarget,
            message: 'Remove this document?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    }

    let additionalDocsList = additionalDocs && additionalDocs.length > 0 &&
        additionalDocs.map((x: any, index: number) => {
            return (
                <div className="row" key={index}>
                    <div className="col-sm-10">
                        <label> {x.fileName}</label>
                    </div>
                    <div className="col-sm-2">
                        <button type="button" style={{ position: 'relative', height: '20px', width: '20px' }} className="btn btn-info btn-sm" onClick={(e: any) => confirmDeleteFile(e, x)}><i style={{ height: '5px', width: '5px', position: 'absolute', top: '50%', left: '50%', margin: '-8px 0 0 -8px' }} className="pi pi-trash"></i></button>
                    </div>
                </div>
            )
        }, this);


    return (
        <>
            <Toast ref={toast} />
            {<BackDrop open={isUpLoading} />}

            <h5>Upload Documents</h5>
            <div className="form-section">
                <div className="row">

                    <input type="file" accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" id="inputFile" onChange={uploadFileHandler} hidden />

                    <div className="col-lg-3 col-md-4">
                        <div className="form-group">
                            <label htmlFor="resume">Resumé/CV</label>
                            <span className="document-name">{resumeFileName}</span>

                            {hasResume && (
                                <>
                                    <button type="button" disabled={isReplaceSending} id={formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Resume)[0].documentID} className="btn btn-blue btn-upload-files" onClick={uploadFileButtonHandler}>Replace File</button>&nbsp;
                                    <button type="button" disabled={isDeleteSending} id={formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Resume)[0].documentID} className="btn btn-info" onClick={deleteFileButtonHandler}><i id={formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.Resume)[0].documentID} className="pi pi-trash"></i></button>
                                </>
                            )}

                            {!hasResume && (
                                <>
                                    <button id="Resume" disabled={isSending} className="btn btn-primary btn-upload-files" onClick={uploadFileButtonHandler}>Upload File</button>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                        <div className="form-group">
                            <label htmlFor="resume">Cover Letter</label>
                            <span className="document-name">{coverLetterFileName}</span>

                            {hasCoverLetter && (
                                <>
                                    <button type="button" disabled={isReplaceSending} id={formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.CoverLetter)[0].documentID} className="btn btn-blue btn-upload-files" onClick={uploadFileButtonHandler}>Replace File</button>&nbsp;
                                    <button type="button" disabled={isDeleteSending} id={formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.CoverLetter)[0].documentID} className="btn btn-info" onClick={deleteFileButtonHandler}><i id={formik.values.documentInfo.filter((x: any) => x.documentTypeID === DocumentType.CoverLetter)[0].documentID} className="pi pi-trash"></i></button>
                                </>
                            )}

                            {!hasCoverLetter && (
                                <>
                                    <button id="CoverLetter" disabled={isSending} className="btn btn-primary btn-upload-files" onClick={uploadFileButtonHandler}>Upload File</button>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="div-documents">
                            <div className="div-left">
                                <label htmlFor="AdditionalDocs">Additional Documents</label>
                            </div>
                            <div className="div-right">
                                <button id="AdditionalDocs" disabled={isSending} className="btn btn-add mr-2" onClick={uploadFileButtonHandler}>Add Document</button>
                            </div>
                        </div>

                        <div style={{ height: '70px', overflowY: 'auto', overflowX: 'hidden' }}>
                            {additionalDocsList}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Documents;