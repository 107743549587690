import * as React from 'react';
import Login from '../Common/Login';

interface IWelcomeProps {
}

const Welcome: React.FunctionComponent<IWelcomeProps> = (props) => {

    return (
        <>
            <Login isWelcome={true} />
        </>
    );
};

export default Welcome;