import { SessionContext } from './../Context/Session/SessionContext';
import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import http from './useHttpClient';
import useGetApiUrls from './useGetApiUrls';
import DocumentUploadInfo from '../Entities/DocumentUploadInfo';
import { Toast } from 'primereact/toast';

const FormData = require('form-data');

const useReplaceDocument = (): [(documentUploadInfo: DocumentUploadInfo, formData: FormData, toast: React.RefObject<Toast>) => void, boolean, DocumentUploadInfo] => {
    const { session } = useContext(SessionContext);
    const { getUpdateUploadDocumentUrl } = useGetApiUrls();
    const [isSending, setIsSending] = useState(false);
    const [replaceDocument, setReplaceDocument] = useState<DocumentUploadInfo>({ documentID: 0, applicantID: 0, applicationID: 0, s3DocumentID: '', fileName: '', documentTypeID: 0 });
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const uploadDocument = useCallback(
        (documentUploadInfo: DocumentUploadInfo, formData: FormData, toast: React.RefObject<Toast>) => {
            //console.log('documentUploadInfo', documentUploadInfo);

            http(session.apiToken)
                .post(getUpdateUploadDocumentUrl, formData,)
                .then((response) => {
                    if (response.data?.documentID === 0) {
                        documentUploadInfo.s3DocumentID = response.data;
                        setReplaceDocument(documentUploadInfo);
                        toast.current?.show({ severity: 'success', detail: 'Document was uploaded successfully', life: 3000 });
                    } else if (!response.data?.result) {
                        toast.current?.show({ severity: 'error', detail: `There was a problem uploading your document: ${response.data?.message}`, life: 3000 });
                    }
                })
                .catch((c) => {
                    console.log(c);
                    toast.current?.show({ severity: 'error', detail: 'There was a problem uploading your document', life: 3000 });
                })
                .finally(() => {
                    //make sending flag off if component is not unmounted
                    if (isMounted.current) {
                        setIsSending(false);
                    }
                });
        }, [isSending]
    );

    return [uploadDocument, isSending, replaceDocument];
};

export default useReplaceDocument;
