import * as React from 'react';
import { useEffect, useState } from 'react';
import AddResearchInterests from './AddResearchInterests';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';

interface IResearchInterestsProps {
    formik: any;
    toast: React.RefObject<Toast>;
}

const ResearchInterests: React.FunctionComponent<IResearchInterestsProps> = ({ formik, toast }) => {

    const [disabled, setDisabled] = useState(false);
    const [selectedInterests, setSelectedInterests] = useState(formik.values.applicationResearchInterestListInfo.filter((x: any) => x.isSelected));
    const [limitReached, setLimitReached] = useState(false);

    const handleOnChange = (e: any) => {
        setSelectedInterests(e.value);
        setLimitReached(e.value.length > 5);
        if (e.value.length > 5) {
            //toast.current?.show({ severity: 'warn', detail: 'No more than six Research Interest can be selected', life: 3000 });
            toast.current?.show({ severity: 'warn', detail: 'The limit of six Research Interest have been selected', life: 3000 });
        }
    }

    useEffect(() => {
        setDisabled(selectedInterests.length > 5);

        formik.values.applicationResearchInterestListInfo.map((x: any) => {
            x.isSelected = false;

            selectedInterests.map((i: any) => {
                if (x.researchInterestID === i.researchInterestID) {
                    x.isSelected = true;
                }
            })
        });
    }, [selectedInterests]);

    return (
        <>
            <h5>Research Interests</h5><mark>Maximum of six</mark>
            <div className="form-section">

                <div className="row">
                    <div>
                        <MultiSelect
                            value={selectedInterests}
                            options={formik.values.applicationResearchInterestListInfo}
                            onChange={handleOnChange}
                            optionLabel="name"
                            placeholder="Select Research Interests"
                            display="chip"
                            filter
                            selectionLimit={6}
                            showSelectAll={false}
                            className="multiselect-custom"
                            panelFooterTemplate={<AddResearchInterests formik={formik} toast={toast} selectedInterests={selectedInterests} setSelectedInterests={setSelectedInterests} disabled={disabled} />}
                            style={{ maxWidth: '50rem' }}
                        />
                    </div>
                    <div className="col-sm-3">
                        {limitReached && (
                            <div className="alert alert-warning" role="alert">
                                The limit of six Research Interest have been selected
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResearchInterests;