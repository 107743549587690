import React, { useContext, useEffect, useRef } from 'react';
import './App.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Container from './Components/Container/Container';
import IdleTimeOut from './Components/Common/IdleTimeOut';

import './Scripts/analytics';
import { Route, Routes } from 'react-router-dom';
import Welcome from './Components/Welcome/Welcome';
import Unauthorized from './Components/Unauthorized/Unauthorized';
import Dashboard from './Components/Dashboard/Dashboard';
import Profile from './Components/Profile/Profile/Profile';
import ProfileView from './Components/Profile/Profile/ProfileView';
import useGetSessionInfo from './Hooks/useGetSessionInfo';
import useGetOTPSession from './Hooks/useGetOTPSession';
import useVersionInformation from './Hooks/useGetVersionInfomation';

import 'bootstrap/dist/css/bootstrap.css';
import './Shared/Style/css/main.css';
import './Shared/Style/css/postdoc.css';

import 'bootstrap/dist/js/bootstrap';
import './Shared/Style/js/main.js';

//import 'primereact/resources/themes/fluent-light/theme.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function App() {
    // pull the session information
    //const [isDevMode] = useGetIsDevMode();
    const [isAuthenticated, isLoading, isUnauthorized] = useGetOTPSession();
    //const [isAuthenticated, isLoading] = useGetSessionInfo();

    const [versionInformationInfo] = useVersionInformation();


    return (
        <>
            <Header />
            {isAuthenticated && !isLoading && (
                <IdleTimeOut>
                 <Routes>
                        <Route path='/otpLogin' element={<Container />} />
                        <Route path='/' element={<Container />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/view" element={<ProfileView />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />
                    </Routes>
                </IdleTimeOut>

            )}
            
            {!isAuthenticated && !isLoading && (
                <Welcome />
            )}
            
            <Footer versionInformationInfo={versionInformationInfo} />
        </>
    )
}

export default App;