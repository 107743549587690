import { SessionContext } from './../Context/Session/SessionContext';
import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import http from './useHttpClient';
import useGetApiUrls from './useGetApiUrls';
import DocumentUploadInfo from '../Entities/DocumentUploadInfo';
import { Toast } from 'primereact/toast';

const FormData = require('form-data');

const useUploadDocument = (): [(documentUploadInfo: DocumentUploadInfo, formData: FormData, toast: React.RefObject<Toast>) => void, boolean, DocumentUploadInfo] => {
    const { session } = useContext(SessionContext);
    const { getUploadDocumentUrl } = useGetApiUrls();
    const [isSending, setIsSending] = useState(false);
    const [uploadedDocument, setUploadedDocument] = useState<DocumentUploadInfo>({ documentID: 0, applicantID: 0, applicationID: 0, s3DocumentID: '', fileName: '', documentTypeID: 0});
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const uploadDocument = useCallback(
        (documentUploadInfo: DocumentUploadInfo, formData: FormData, toast: React.RefObject<Toast>) => {
       //let random = Math.random;
            //console.log('documentUploadInfo', documentUploadInfo);

            http(session.apiToken)
                .post(getUploadDocumentUrl, formData, )
                .then((response) => {
                    if (response.data?.documentID) {
                        setUploadedDocument(response.data);
                        toast.current?.show({ severity: 'success', detail: 'Document was uploaded successfully', life: 3000 });
                    } else if (!response.data?.result) {
                        toast.current?.show({ severity: 'error', detail: `There was a problem uploading your document: ${response.data?.message}`, life: 3000 });
                    }
                    
               })
                .catch((c) => {
                    console.log(c);
                    toast.current?.show({ severity: 'error', detail: 'There was a problem uploading your document', life: 3000 });
                })
                .finally(() => {
                    //make sending flag off if component is not unmounted
                    if (isMounted.current) {
                        setIsSending(false);
                    }
                });
        }, [isSending]
    );

    return [uploadDocument, isSending, uploadedDocument];
};

export default useUploadDocument;
