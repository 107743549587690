import * as React from 'react';
import { Link } from 'react-router-dom';
import ApplicantInfo from '../../Entities/ApplicantInfo';
import Highlighter from './Highlighter';
import { Tooltip } from 'primereact/tooltip';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { SessionContext } from '../../Context/Session/SessionContext';
import { ApplicationStatus } from '../../Utils/Enums/Enum';

interface IApplicantCardProps {
    applicant: ApplicantInfo;
    canCopy: boolean;
    handleCopyClick: any;
}

const ApplicantCard: React.FunctionComponent<IApplicantCardProps> = ({ applicant, canCopy, handleCopyClick }) => {
    const navigate = useNavigate();
    const { session } = useContext(SessionContext);

    const status = applicant.status?.toLowerCase() === ApplicationStatus.toString(ApplicationStatus.Initial).toLowerCase();

    applicant.researchAreas = applicant && applicant.researchAreas ? applicant.researchAreas.replaceAll(',', ', ') : '';

    const handleEditClick = (e: any) => {
        let applicationID = parseInt(e.target.value);
        
        session.applicationID = applicationID;
        navigate('/profile', { replace: true });

        e.preventDefault();
    }

    const handleViewClick = (e: any) => {
        let applicationID = parseInt(e.target.value);

        session.applicationID = applicationID;
        session.degreeName = applicant.degree;
        session.citizenshipName = applicant.citizenship;
        session.researchInterests = applicant.researchAreas;
        navigate('/view', { replace: true });

        e.preventDefault();
    }

    return (
        <>
            <div className="applicants-card mt-3">
                <table className="table">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="candidate-info d-flex justify-content-between">
                                <div className="candidate d-flex align-items-center">
                                    {!status && (
                                        <>
                                            <span className="date-submitted">Date Submitted:</span>
                                            <span className="name">{applicant.submittedDateString}</span>
                                            <span className="status new">{applicant.status}</span>
                                        </>
                                    )}
                                    {status && (
                                        <>
                                            <span className="date-submitted">Date Submitted:</span>
                                            <span className="name"></span>
                                            <span className="status new">Draft</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="buttons-container">
                                {applicant.status && applicant.status.toLowerCase() !== ApplicationStatus.toString(ApplicationStatus.Initial).toLowerCase() && (
                                    <button className="btn btn-blue btn-resume buttons-container-item" value={applicant.applicationID} onClick={handleViewClick}>View Application</button>
                                )}
                                {applicant.status && applicant.status.toLowerCase() === ApplicationStatus.toString(ApplicationStatus.Initial).toLowerCase() && (
                                    <button className="btn btn-blue btn-resume buttons-container-item" value={applicant.applicationID} onClick={handleEditClick}>Edit Application</button>
                                )}
                                {canCopy && applicant.status && applicant.status.toLowerCase() === ApplicationStatus.toString(ApplicationStatus.Accepted).toLowerCase() && (
                                    <button className="btn btn-blue btn-view buttons-container-item" value={applicant.applicationID} onClick={handleCopyClick}>Copy Application</button>
                                )}
                                {canCopy && applicant.status && applicant.status.toLowerCase() === ApplicationStatus.toString(ApplicationStatus.Withdrawn).toLowerCase() && (
                                    <button className="btn btn-blue btn-view buttons-container-item" value={applicant.applicationID} onClick={handleCopyClick}>Copy Application</button>
                                )}
                                {canCopy && applicant.status && applicant.status.toLowerCase() === ApplicationStatus.toString(ApplicationStatus.Archived).toLowerCase() && (
                                    <button className="btn btn-blue btn-view buttons-container-item" value={applicant.applicationID} onClick={handleCopyClick}>Copy Application</button>
                                )}
                                {canCopy && applicant.status && applicant.status.toLowerCase() === ApplicationStatus.toString(ApplicationStatus.Ineligible).toLowerCase() && (
                                                <button className="btn btn-blue btn-view buttons-container-item" value={applicant.applicationID} onClick={handleCopyClick}>Copy Application</button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <p className="title">College/University</p>
                            <p className="description py-1">
                                {
                                    <Highlighter
                                        text={applicant.collegeOrUniversity}
                                    ></Highlighter>
                                }
                            </p>
                        </div>
                        <div className="col-lg-2">
                            <p className="title">Degree</p>
                            <p className="description">
                                {<Highlighter text={applicant.degree}></Highlighter>}
                            </p>
                        </div>
                        <div className="col-lg-2">
                            <p className="title">Major Field of Study</p>
                            <p className="description">
                                {<Highlighter text={applicant.majorFieldOfStudy}></Highlighter>}
                            </p>
                        </div>
                        <div className="col-lg-2">
                            <p className="title">Anticipated Graduation</p>
                            <p className="description">
                                {
                                    <Highlighter
                                        text={(applicant.graduationDateString !== '12/31/9999' && applicant.graduationDateString !== '1/1/0001') ? applicant.graduationDateString : ''}
                                    ></Highlighter>
                                }
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <p className="title">Research Interests</p>
                            <Tooltip target=".description" mouseTrack mouseTrackLeft={10} />
                            <p
                                className="description"
                                data-pr-tooltip={applicant.researchAreas}
                            >
                                {<Highlighter text={applicant.researchAreas}></Highlighter>}
                            </p>
                        </div>
                    </div>
                </table>
            </div>
        </>
    );
};

export default ApplicantCard;
