import * as React from 'react';
import { Dispatch, useState, SetStateAction } from 'react';
import { Button } from 'primereact/button';
import ResearchInterest from '../../../Entities/ResearchInterest';
import { Toast } from 'primereact/toast';

interface IAddResearchInterestsProps {
    formik: any;
    toast: React.RefObject<Toast>;
    selectedInterests: any;
    setSelectedInterests: Dispatch<SetStateAction<any>>;
    disabled: boolean;
}

const AddResearchInterests: React.FunctionComponent<IAddResearchInterestsProps> = ({ formik, toast, selectedInterests, setSelectedInterests, disabled }) => {
    const [toggleAdd, setToggleAdd] = useState(false);
    const [value, setValue] = useState('');

    const handleAddClick = (e: any) => {
        if (value.length >= 3) {
            let result = formik.values.applicationResearchInterestListInfo.filter((x: any) => x.name.toUpperCase().includes(value.toUpperCase()));
            if (result.length > 0) {
                toast.current?.show({ severity: 'warn', detail: `${value} already exits`, life: 3000 });
            } else {

                var random = Math.floor(Math.random() * 4500) + 10000;

                const item = {
                    researchInterestID: random,
                    name: value,
                    applicationResearchInterestID: 0,
                    isSelected: false,
                    applicationID: 0,
                    isVisible: true
                };

                setSelectedInterests([...selectedInterests, item]);

                formik.values.applicationResearchInterestListInfo.push(item);
                formik.values.applicationResearchInterestListInfo.sort((a: any, b: any) => a.name.localeCompare(b.name));

                setValue('');
            }
        } else {
            toast.current?.show({ severity: 'warn', detail: 'Need at least three letters', life: 3000 });
        }

        e.preventDefault();
    }

    const handleChange = (e: any) => {
        setValue(e.target.value);
    }
    
    return (
        <>
            <div className="d-flex justify-content-center">
                <Button
                    icon={toggleAdd ? "pi pi-minus" : "pi pi-plus"}
                    className="p-button-info p-button-text p-button-sm my-1"
                    onClick={(e) => {
                        setToggleAdd((prev) => !prev);
                    }}
                    label="Add Research Interest"
                />
            </div>

            {toggleAdd && (
                <>
                    <div style={{ padding: '0.5rem' }}>
                        <label className="mandatory" htmlFor="addResearchInterestName">Research Interest</label>
                        <div className="row">
                            <div className="col-sm-10">
                                <input
                                    id="addResearchInterestName"
                                    type="text"
                                    maxLength={100}
                                    className="form-control"
                                    value={value}
                                    onChange={handleChange}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="col-sm-2">
                                <button className="btn btn-blue" type="button" onClick={handleAddClick} disabled={disabled}>Add</button>
                            </div>
                        </div>

                    </div>
                </>
            )}
        </>
    );
};

export default AddResearchInterests;