import jQuery  from 'jquery'

(function ($) {
    'use strict';

    function viewport () {
        var e = window,
            a = 'inner';

        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }

        return {
            width: e[a + 'Width'],
            height: e[a + 'Height']
        };
    }

    function mobileCheck () {
        if (viewport().width < 992) {
            window.isMobile = true;
        } else {
            window.isMobile = false;
        }
    }

    window.mobileCheck = mobileCheck;
}(jQuery));

(function ($) {
    'use strict';

    $.fn.mobileNavHeight = function () {

        var self = this,
            $navbarCollapse = $('.navbar-collapse'),
            isOpen = false;

        if ($navbarCollapse.hasClass('show')) {
            isOpen = true;
        }

        function getNavHeight () {
            var tempHeight = 0;

            $navbarCollapse.children().each(function () {
                tempHeight += $(this).outerHeight(true);
            });

            return tempHeight;
        }

        setTimeout(function () {
            var newHeight,
                parentUl;

            if (self.hasClass('navbar-toggler')) {
                // newHeight = $('.navbar-nav').not('.navbar-nav.footer').height();
                newHeight = getNavHeight();

                $navbarCollapse.css('max-height', newHeight);

                if (isOpen) {
                    $navbarCollapse.css('height', '');
                } else {
                    setTimeout(function () {
                        $navbarCollapse.css('height', newHeight);
                    }, 401);
                }
            } else {
                if (self.hasClass('sub-nav')) {
                    newHeight = self.closest('li').children('ul').height();
                    // t.closest('li').children('ul').css('border','3px solid red');
                    // console.log(t.closest('li').find('ul').first());
                    // console.log('in: ' + newHeight);
                    $navbarCollapse.css('max-height', newHeight);

                    if (isOpen) {
                        $navbarCollapse.css('height', newHeight);
                    }
                } else if (self.hasClass('sub-nav-back')) {
                    parentUl =  self.parent('ul').parent().closest('ul');

                    if (parentUl.hasClass('navbar-nav')) {
                        newHeight = getNavHeight();
                    } else {
                        newHeight = parentUl.height();
                    }

                    // t.parent('ul').parent().closest('ul').css('border','3px solid red');
                    // console.log(t.parent('ul').parent().closest('ul'));
                    // console.log('back: ' + newHeight);
                    $navbarCollapse.css('max-height', newHeight);

                    if (isOpen) {
                        $navbarCollapse.css('height', newHeight);
                    }
                }
            }
        }, 1);

        return this;
    };

    function buildNavigation () {
        var navLevel = 0,
            $navbar = $('.navbar').not('.navbar.footer'),
            $navbarToggler = $navbar.find('.navbar-toggler'),
            $navBarNav = $navbar.find('.navbar-nav'),
            $navBarNavLi = $navBarNav.find('li'),
            $navBarNavA = $navBarNavLi.find('a'),
            $navBarCollapse = $navbar.find('.navbar-collapse'),
            $navBarCollapseChildren = $navBarCollapse.children(),
            $subNavA = $navBarNav.children('li > ul > li > a'),
            $subNav,
            $subNavBack;
            // hoverTimeout,
            // focusOutTimeout;

        $navbarToggler.click(function () {
            if (window.isMobile) {
                $(this).mobileNavHeight();
            }
        });

        $navBarNavLi.on('mouseenter focusin', function () {
            var self = $(this),
                ul = self.children('ul'),
                li = ul.children('li').not('.sub-nav, .sub-nav-back'),
                ulHeight = 0;

            if (!window.isMobile) {
                li.each(function () {
                    ulHeight += $(this).height();
                });

                ul.height(ulHeight)
                    .addClass('active');

                setTimeout(function () {
                    if (ul.hasClass('active')) {
                        ul.css('overflow', 'visible');
                    }
                }, 301);
            }
        });

        $navBarNavLi.on('mouseleave focusout', function () {
            var ul = $(this).children('ul');

            if (!window.isMobile) {
                ul.removeClass('active');

                setTimeout(function () {
                    if (!ul.hasClass('active')) {
                        ul.css('overflow', 'hidden')
                            .height('');
                    }
                }, 301);
            }
        });

        $navBarNavA.each(function () {
            $(this).attr('data-title', $(this).text());
        });

        $navBarNavLi.each(function () {
            var self = $(this),
                ul = self.children('ul');

            if (ul.length !== 0) {
                self.children('a').append('<span class="sub-nav">&#9654;</span>');
                ul.prepend('<li class="sub-nav-back">&#9664; Back</span>');
            }
        });

        // $subNav = $('.navbar .navbar-nav .sub-nav').not('.navbar.footer .navbar-nav .sub-nav');
        $subNav = $navBarNav.find('.sub-nav');
        $subNavBack = $navBarNav.find('.sub-nav-back');

        $subNav.click(function (event) {
            event.stopPropagation();
            event.preventDefault();

            var self = $(this),
                li = self.closest('li');

            navLevel++;
            li.siblings('li').find('ul').css('display', 'none');
            li.children('ul').css('display', 'block');
            // $navBarNav.css('left', '-' + navLevel + '00%');
            $navBarCollapseChildren.css('left', '-' + navLevel + '00%');
            if (window.isMobile) {
                setTimeout(function () {
                    self.mobileNavHeight();
                }, 1);
            }
        });

        $subNavBack.click(function (event) {
            event.stopPropagation();
            event.preventDefault();

            navLevel--;

            if (navLevel <= 0) {
                navLevel = 0;
                // $navBarNav.css('left', '0');
                $navBarCollapseChildren.css('left', '0');
            } else {
                // $navBarNav.css('left', '-' + navLevel + '00%');
                $navBarCollapseChildren.css('left', '-' + navLevel + '00%');
            }

            if (window.isMobile) {
                $(this).mobileNavHeight();
            }
        });

        $subNavA.on('focusin', function () {
            var ul = $(this).closest('ul'),
                ulHeight = 0;

            ul.parent('li').addClass('open');

            if (!window.isMobile) {
                ul.children('li').not('.sub-nav, .sub-nav-back').each(function () {
                    ulHeight += $(this).height();
                });
                ul.height(ulHeight);
            }
        });

        $subNavA.on('focusout', function () {
            var ul = $(this).closest('ul');

            ul.parent('li').removeClass('open');
            if (!window.isMobile) {
                ul.height('');
            }
        });
    }

    window.buildNavigation = buildNavigation;
}(jQuery));

(function ($) {
    'use strict';

    $('#skip-nav').click(function () {
        var skipTo = '#' + this.href.split('#')[1];

        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {
            $(this).removeAttr('tabindex');
        }).focus();
    });
}(jQuery));

(function ($) {
    'use strict';

    $(document).ready(function () {
        var navbarCollapse = $('.navbar-collapse'),
            sidebar = document.getElementById('sidebar'),
            sidebarTrigger= document.getElementById('sidebar-trigger'),
            toggles = document.getElementsByClassName('toggle-switch'),
            detailHeader = $('.detail-header'),
            i,
            i2,
            i3,
            index,
            labels,
            switchGroup;


        window.mobileCheck();
        // window.buildNavigation();
        // window.textResizerInit();
        // window.parallaxInit();
        // window.addFileReaderDownloadLinks();

        // $('#content').breakLongURLs();
        // $('body').convertSVGsToInline();
        // $('#example-table').addMobileTableHeaders();
        // $('[data-toggle="tooltip"]').tooltip();


        $(window).resize(function () {
            window.mobileCheck();

            if (!window.isMobile) {
                // hide open mobile nav when sizing up to desktop
                navbarCollapse.height('');
            }
        });

        // tooltip
        $('[data-toggle="tooltip"]').tooltip();

        // Sidebar toggle
        if (sidebarTrigger) {
            sidebarTrigger.addEventListener('click', function () {
                if (sidebar.classList.contains('open')) {
                    sidebar.classList.remove('open');
                } else {
                    sidebar.classList.add('open');
                }
            });
        }

        // Toggle button
        toggles = document.getElementsByClassName('toggle-switch');

        for (i = 0; i < toggles.length; i++) {
            labels = toggles[i].getElementsByTagName('label');

            for (i2 = 0; i2 < labels.length; i2++) {
                labels[i2].addEventListener("click", function () {
                    index = Array.prototype.indexOf.call(this.parentNode.children, this);
                    switchGroup = this.parentNode;

                    for (i3 = 0; i3 <= 3; i3++) {
                        switchGroup.classList.remove("active-index-" + i3);
                    }

                    switchGroup.classList.add("active-index-" + index);
                });
            }
        }

        // Fixed tob banner
        window.addEventListener('scroll', function () {
            if (window.scrollY > 140) {
                detailHeader.addClass("fixed-header");
            } else {
                detailHeader.removeClass('fixed-header');
            }
        });

    });

}(jQuery));
