import { SessionContext } from './../Context/Session/SessionContext';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import http from './useHttpClient';
import useGetApiUrls from './useGetApiUrls';
import { Toast } from 'primereact/toast';
import ApplicantInfo from '../Entities/ApplicantInfo';

const useCopyApplication = (): [(applicantID: number, applicationID: number, toast: React.RefObject<Toast>) => void, boolean, ApplicantInfo] => {
    const { getCopyApplicationUrl } = useGetApiUrls();
    const { session } = useContext(SessionContext);
    const [isSending, setIsSending] = useState(true);
    const [applicant, setApplicant] = useState<ApplicantInfo>({} as ApplicantInfo);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const copyApplication = useCallback(
        (applicantID: number, applicationID: number, toast: React.RefObject<Toast>) => {
            http(session.apiToken)
                .post(`${getCopyApplicationUrl}/${applicantID}/${applicationID}`)
                .then((response) => {
                    // TODO: add toast save is successful
                    if (response.data !== null) {
                        setApplicant(response.data);
                        toast.current?.show({ severity: 'success', detail: 'Application was copied successfully', life: 3000 });
                    } else {
                        toast.current?.show({ severity: 'error', detail: 'There was a problem copying your Application', life: 3000 });
                    }
                    setIsSending(false);
                })
                .catch((c) => {
                    // TODO: add toast save is NOT successful
                    console.log(c);
                    setIsSending(false);
                    toast.current?.show({ severity: 'error', detail: 'There was a problem copying your Application', life: 3000 });
                })
                .finally(() => {
                    //make sending flag off if component is not unmounted
                    if (isMounted.current) {
                        setIsSending(false);
                    }
                });
        }, [isSending]
    );

    return [copyApplication, isSending, applicant];
};

export default useCopyApplication;