import { SESSION_INITIALIZED, SESSION_ESTABLISHED, SESSION_SAVED, SESSION_LOGOUT } from '../../Utils/Constants/Session';
import Session from '../../Entities/Session';

const SessionReducer = (state: Session, action: { type: string, payload: Session }) => {

    switch (action.type) {
        case SESSION_ESTABLISHED:
            return {
                ...state
                , apiToken: action.payload.apiToken
                , apiUrl: action.payload.apiUrl
                , applicantID: action.payload.applicantID
                , displayName: action.payload.displayName
                , emailAddress: action.payload.emailAddress
                , firstName: action.payload.firstName
                , hasApplicants: action.payload.hasApplicants
                , iPAddress: action.payload.iPAddress
                , isAuthenticated: true
                , lastName: action.payload.lastName
                , logoutServer: action.payload.logoutServer
                , applicationID: action.payload.applicationID
                , userAgent: action.payload.userAgent
                , idleTimeOutInMins: action.payload.idleTimeOutInMins
            }
        case SESSION_INITIALIZED:
            return {
                ...state,
                emailAddress: action.payload.emailAddress,
                isAuthenticated: false
            }
        case SESSION_SAVED:
           return {
                ...state,
                emailAddress: action.payload.emailAddress,
                firstName: action.payload.firstName
            }
        case SESSION_LOGOUT:
           return {
                ...state
               , isAuthenticated: false
               , apiToken: ''
               , apiUrl: ''
               , applicantID: 0
               , displayName: ''
               , emailAddress: ''
               , firstName: ''
               , hasApplicants: false
               , iPAddress: ''
               , lastName: ''
               , logoutServer: ''
               , applicationID: 0
               , userAgent: ''
            }

        default:
       return state;
    }
}

export default SessionReducer;