import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../Context/Session/SessionContext';
import Citizenship from '../Entities/Citizenship';
import useGetApiUrls from './useGetApiUrls';
import http from './useHttpClient';

const useGetCitizenship = (): [Citizenship[]] => {
    const { session } = useContext(SessionContext);
    const { getCitizenshipsUSAFirstUrl } = useGetApiUrls();

    const [citizenship, setCitizenship] = useState<Citizenship[]>({} as Citizenship[])

    useEffect(() => {
        http(session.apiToken).post(`${getCitizenshipsUSAFirstUrl}`)
            .then(response => {
                setCitizenship(response.data);
            })
            .catch((ex) => {
                console.log(ex);
            })
        setCitizenship({} as Citizenship[]);
    }, []);

    return [citizenship]
}

export default useGetCitizenship;