export enum Default {

}

export enum DocumentType {
    Resume = 1,
    CoverLetter = 2,
    Additional = 3
}

export enum ApplicationStatus {
    Initial = 1,
    New = 2,
    Available = 3,
    Accepted = 4,
    Ineligible = 5,
    Withdrawn = 6,
    Archived = 7
}

export enum ProfileMode {
    Edit = 0,
    View = 1
}

export enum DegreeType {
    Other = -1,
    None = 1,
};


export namespace ApplicationStatus {
    export function toString(status: ApplicationStatus): string {
        return ApplicationStatus[status];
    }

    export function fromString(status: string): ApplicationStatus {
        return (ApplicationStatus as any)[status];
    }
}