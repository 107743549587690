import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../Context/Session/SessionContext';
import Degree from '../Entities/Degree';
import useGetApiUrls from './useGetApiUrls';
import http from './useHttpClient';

const useGetDegree = (): [Degree[]] => {
    const { session } = useContext(SessionContext);
    const { getDegreesOtherUrl } = useGetApiUrls();

    const [degree, setDegree] = useState<Degree[]>({} as Degree[])

    useEffect(() => {
        http(session.apiToken).post(`${getDegreesOtherUrl}`)
            .then(response => {
                setDegree(response.data);
            })
            .catch((ex) => {
                console.log(ex);
            })
        setDegree({} as Degree[]);
    }, []);

    return [degree];
}

export default useGetDegree;