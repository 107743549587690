import * as React from 'react';
import useGetCitizenship from '../../../Hooks/useGetCitizenship';

interface IPersonalInformationProps {
    formik: any;
}

const PersonalInformation: React.FunctionComponent<IPersonalInformationProps> = ({ formik }) => {
    const [citizenships] = useGetCitizenship();

    let citizenshipsList = citizenships.length > 0 &&
        citizenships.map(x => {
            return (
                <option key={x.citizenshipID} value={x.citizenshipID}>{x.citizenshipName}</option>
            )
        }, this);

    return (
        <>
            <h5>Personal Information</h5>
            <div className="form-section">
                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <div className="form-group">
                            <label className="mandatory" htmlFor="firstName">First Name</label>
                            <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                maxLength={64}
                                className={formik.touched.firstName && formik.errors.firstName ? 'form-control form-control-validation-error' : 'form-control'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName ?? ''}
                            />
                            {formik.touched.firstName && formik.errors.firstName ? (
                                <div>{formik.errors.firstName ?? ''}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="form-group">
                            <label htmlFor="middleName">Middle Name (optional)</label>
                            <input
                                id="middleName"
                                name="middleName"
                                type="text"
                                maxLength={64}
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.middleName ?? ''}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="form-group">
                            <label className="mandatory" htmlFor="lastName">Last Name</label>
                            <input
                                id="lastName"
                                name="lastName"
                                type="text"
                                maxLength={64}
                                className={formik.touched.lastName && formik.errors.lastName ? 'form-control form-control-validation-error' : 'form-control'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName ?? ''}
                            />
                            {formik.touched.lastName && formik.errors.lastName ? (
                                <div>{formik.errors.lastName ?? ''}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-4">
                        <div className="form-group">
                            <label htmlFor="emailAddress">Email</label>
                            <input
                                id="emailAddress"
                                name="emailAddress"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                value={formik.values.emailAddress ?? ''}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="form-group">
                            <label className="mandatory" htmlFor="phone">Phone</label>
                            <input
                                id="phone"
                                name="phone"
                                type="text"
                                maxLength={20}
                                className={formik.touched.phone && formik.errors.phone ? 'form-control form-control-validation-error' : 'form-control'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone ?? ''}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                                <div>{formik.errors.phone ?? ''}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="form-group">
                            <label className="mandatory" htmlFor="applicationInfo.citizenshipID">Citizenship</label>
                            <select
                                id="applicationInfo.citizenshipID"
                                name="applicationInfo.citizenshipID"
                                className={formik.touched.applicationInfo && formik.touched.applicationInfo.citizenshipID && formik.errors.citizenshipID ? 'form-control form-control-validation-error' : 'form-control'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={(formik.values.applicationInfo && formik.values.applicationInfo.citizenshipID) ?? 0}
                           >
                                {citizenshipsList}
                            </select>
                            {formik.touched.applicationInfo && formik.touched.applicationInfo.citizenshipID && formik.errors.citizenshipID ? (
                                <div>{formik.errors.citizenshipID}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PersonalInformation;