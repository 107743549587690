import * as React from "react";

interface IAdditionalQualificationsProps {
    formik: any;
}

const AdditionalQualifications: React.FunctionComponent<IAdditionalQualificationsProps> = ({ formik }) => {

    return (
        <>
            <h5>Additional Qualifications</h5>
            <div className="form-section">
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="qualificationSummaryInfo.certifications">Certifications or Additional Trainings</label>
                            <textarea rows={3}
                                id="qualificationSummaryInfo.certifications"
                                name="qualificationSummaryInfo.certifications"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={(formik.values.qualificationSummaryInfo && formik.values.qualificationSummaryInfo.certifications) ?? ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="qualificationSummaryInfo.researchExperience">Research Experiences</label>
                            <textarea rows={3}
                                id="qualificationSummaryInfo.researchExperience"
                                name="qualificationSummaryInfo.researchExperience"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={(formik.values.qualificationSummaryInfo && formik.values.qualificationSummaryInfo.researchExperience) ?? ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="qualificationSummaryInfo.publications">Publications</label>
                            <textarea rows={3}
                                id="qualificationSummaryInfo.publications"
                                name="qualificationSummaryInfo.publications"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={(formik.values.qualificationSummaryInfo && formik.values.qualificationSummaryInfo.publications) ?? ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="qualificationSummaryInfo.presentations">Presentations</label>
                            <textarea rows={3}
                                id="qualificationSummaryInfo.presentations"
                                name="qualificationSummaryInfo.presentations"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={(formik.values.qualificationSummaryInfo && formik.values.qualificationSummaryInfo.presentations) ?? ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="qualificationSummaryInfo.awardsAndHonors">Awards and Honors</label>
                            <textarea rows={3}
                                id="qualificationSummaryInfo.awardsAndHonors"
                                name="qualificationSummaryInfo.awardsAndHonors"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={(formik.values.qualificationSummaryInfo && formik.values.qualificationSummaryInfo.awardsAndHonors) ?? ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="qualificationSummaryInfo.leaderShipOutreachExperience">Leadership and Outreach Experience</label>
                            <textarea rows={3}
                                id="qualificationSummaryInfo.leaderShipOutreachExperience"
                                name="qualificationSummaryInfo.leaderShipOutreachExperience"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={(formik.values.qualificationSummaryInfo && formik.values.qualificationSummaryInfo.leaderShipOutreachExperience) ?? ''}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdditionalQualifications;