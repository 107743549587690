import * as React from 'react';
import { useEffect, useState } from 'react';
import useGetDegree from '../../../Hooks/useGetDegree';
import Detail from './Detail';

interface IAcademicDetailsProps {
    formik: any;
}

const AcademicDetails: React.FunctionComponent<IAcademicDetailsProps> = ({ formik }) => {
    const [degrees] = useGetDegree();
    const applicationID = formik.values.applicationID;

    const addDetail = (e: any) => {
        e.preventDefault();

        const now = Date.now();
        const today = new Date(now);

        var random = Math.floor(Math.random() * 4500) + 10;

        let highestLevelDegreeCount = formik.values.academicDetailInfo.filter((x: any) => (x.isVisible === true || x.isVisible === 'true') && x.highestLevelDegree === true || x.highestLevelDegree === 'Yes').length;

        const item = {
            academicDetailID: random,
            applicationID: applicationID,
            institution: '',
            degreeID: 0,
            fieldOfStudy: '',
            highestLevelDegree: highestLevelDegreeCount === 0,
            thesis: '',
            gpa: 0,
            currentCumelativeGPA: 0,
            startDate: today,
            graduationDate: today,
            graduationDateString: '',
            startDateString: '',
            isVisible: true,
            disabled: false
        };

        setDetails([...details, item]);
        formik.values.academicDetailInfo.push(item);
    }

    const [details, setDetails] = useState(formik.values.academicDetailInfo);

    const academicDetailsLists = details && degrees && degrees.length > 0 && details.map((item: any, index: number) =>
        <div key={item.academicDetailID.toString()}>
            <Detail key={item.academicDetailID.toString()} formik={formik} index={index} degrees={degrees}  />
        </div>
    );

    const [canAddDegree, setCanAddDegree] = useState(true);

    useEffect(() => {
        let isValid = true;
        if (formik.errors.detailErrors && formik.errors.detailErrors[0]) {
            formik.errors.detailErrors.map((de: any, index: number) => {
                if (index === 0) {
                    Object.values(de).map(x => {
                        if (x !== '') {
                            isValid = false;
                        }
                    });
                }
            });

            setCanAddDegree(!isValid);
        }
    }, [formik.errors.detailErrors])

    return (
        <>
            <h5>Academic Details</h5><mark>Please include all relevant degrees, starting with your highest/most-recent degree.</mark>
            <div className="academic-details">
                <div className="form-section mb-2">
                    {academicDetailsLists}
                </div>
            </div>

            <button id="addDegreeButton" className="btn btn-add mb-4" onClick={addDetail}>Add Degree</button>
        </>
    );
};

export default AcademicDetails;