import { useEffect, useState } from 'react';
import http from './useHttpClient';

const useGetOtpUrl = (): [string] => {
    const [otpUrl, setOtpUrl] = useState('');
    
    useEffect(() => {
        http('')
            .post('/api/data/GetOtpUrl', {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((c) => {
                if (c.data !== null) {
                    setOtpUrl(c.data);
                    return;
                }
                setOtpUrl('');
            })
            .catch((c) => {
                setOtpUrl('');
            });
    }, []);
    return [otpUrl];
};

export default useGetOtpUrl;