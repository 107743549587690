import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { SessionContext } from '../../Context/Session/SessionContext';
import useGetApplicantList from '../../Hooks/useGetApplicationList';
import { DataView, DataViewSortOrderType } from 'primereact/dataview';
import ApplicantInfo from '../../Entities/ApplicantInfo';
import ApplicantCard from './ApplicantCard';
import { ApplicationStatus } from '../../Utils/Enums/Enum';
import useCopyApplication from '../../Hooks/useCopyApplication';
import { Toast } from 'primereact/toast';

interface IDashboardProps {
}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {
    const toast = useRef<Toast>(null);

    const { session } = useContext(SessionContext);
    const [loading, setLoading] = useState(false);
    const applicants = useGetApplicantList(session.applicantID ?? 0, setLoading);
    const [copyApplication, isSending, applicant] = useCopyApplication();
    const [canCopy, setCanCopy] = useState(false);

    const [sortOrder, setSortOrder] = useState<DataViewSortOrderType>(1);
    const [sortField, setSortField] = useState('');

    const handleCopyClick = (e: any) => {
        let applicationID = parseInt(e.target.value);
        copyApplication(session.applicantID ?? 0, applicationID, toast);

        e.preventDefault();
    }

    const itemTemplate = (applicant: ApplicantInfo) => {
        if (!applicant) {
            return;
        }
        return <ApplicantCard applicant={applicant} canCopy={canCopy} handleCopyClick={handleCopyClick} />;
    };

    const checkCopy = () => {
        const drafted = applicants.filter((x: any) => x.status.toLowerCase() === ApplicationStatus.toString(ApplicationStatus.Initial).toLowerCase() ||
            x.status.toLowerCase() === ApplicationStatus.toString(ApplicationStatus.New).toLowerCase() ||
            x.status.toLowerCase() === ApplicationStatus.toString(ApplicationStatus.Available).toLowerCase());

        setCanCopy(drafted === null || drafted.length === 0)
    }

    useEffect(() => {
        if (!loading && applicants.length > 0 && applicant) {
            applicants.unshift(applicant);

            checkCopy();
        }
    }, [applicant])

    useEffect(() => {
        if (!loading && applicants.length > 0) {

            checkCopy();
        }
    }, [loading]);

    useEffect(() => {
        if (session.isSubmitted) {
            toast.current?.show({ severity: 'success', detail: 'Application was submitted successfully', life: 3000 });
            session.isSubmitted = false;
        }
    }, [session.isSubmitted])

    return (
        <>
            <Toast ref={toast} />

            <div className="navbar-container">
                <nav className="navbar navbar-dark" aria-label="navigation">
                    <ul className="nav nav-tabs" id="navbarNav">
                        <li className="nav-item">
                            <a className="nav-link active" href="/" id="anch_1">Applications</a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div className="div-footer-padding">
                <div id="content" className="container-fluid content" role="main">
                    <div className="applicants">
                        <h1>My Applications</h1>
                    </div>

                    <section className="applicants-table mt-3">
                        <section className="applicants-table mt-5">
                            <DataView
                                value={applicants}
                                itemTemplate={itemTemplate}
                                paginator
                                rows={4}
                                loading={loading}
                                alwaysShowPaginator={false}
                                sortOrder={sortOrder}
                                sortField={sortField}
                            />
                        </section>
                    </section>
                </div>
            </div>
        </>
    );
};

export default Dashboard;