import * as React from 'react';
import useGetOtpUrl from '../../Hooks/useGetOtpUrl';
import WelcomeLanguage from './WelcomeLanguage';

interface ILoginProps {
    isWelcome: boolean;
}

const Login: React.FunctionComponent<ILoginProps> = ({ isWelcome }) => {
    const [otp] = useGetOtpUrl();

    const otpLogin = (e: any) => {
        window.location.href = otp;
        e.preventDefault();
    }

    return (
        <>
            <nav className="navbar navbar-container" id="navbarSupportedContent"></nav>
            <div className="landing-content">
                <div id="content" className="container-fluid content" role="main">
                    <div className="landing-section">
                        <div className="row">
                            <div className="col-lg-8 col-md-6">
                                <div>
                                    <h3 className="heading-white">Welcome to the NIAID PostDoc Application System</h3>
                                </div>
                                <div className="text-section text-section-white">
                                    {isWelcome && (
                                        <>
                                            <WelcomeLanguage displayIntro={false} displayInfo={true} />
                                        </>
                                    )}
                                    {!isWelcome && (
                                        <>
                                            <h3>Session Expired</h3>
                                            <p>Your session has expired</p>
                                        </>
                                    )}
                                </div>
                                <div className="text-section">
                                    <WelcomeLanguage displayIntro={true} displayInfo={false} />
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <h4 className="heading-white font-weight-light">Ready to apply?</h4>
                                <div className="pt-1">
                                    <button className="MuiButtonBase-root MuiButton-root btn-guide" tabIndex={0} type="button" id="ecps-login-btn" onClick={otpLogin}>
                                        <span className="MuiButton-label">PostDoc Login</span>
                                        <span className="MuiTouchRipple-root" />
                                        <div className="chevron-right">
                                        </div>
                                    </button>
                                    <div className="mt-3" >
                                        <a href={otp} className="link-with-arrow">Register new account</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;