import * as React from 'react';

interface IResumeProps {
    formik: any;
}

const Resume: React.FunctionComponent<IResumeProps> = ({ formik }) => {

    return (
        <>
            <h5>Resumé/CV</h5><mark>Information included in the text boxes below will be searchable for NIAID investigators - contents of attached documents (uploaded above) are not searchable.</mark>
            <div className="form-section">
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="mandatory" htmlFor="qualificationSummaryInfo.personalStatement">Personal Statement</label>
                            <textarea rows={3}
                                id="qualificationSummaryInfo.personalStatement"
                                name="qualificationSummaryInfo.personalStatement"
                                className={formik.touched.qualificationSummaryInfo && formik.touched.qualificationSummaryInfo.personalStatement && formik.errors.personalStatement ? 'form-control form-control-validation-error' : 'form-control'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={(formik.values.qualificationSummaryInfo && formik.values.qualificationSummaryInfo.personalStatement) ?? ''}
                            />
                            {formik.touched.qualificationSummaryInfo && formik.touched.qualificationSummaryInfo.personalStatement && formik.errors.personalStatement ? (
                                <div>{formik.errors.personalStatement}</div>
                            ) : null}
                       </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="mandatory" htmlFor="qualificationSummaryInfo.fullText">Resumé/CV Full Text</label>
                            <textarea rows={3}
                                id="qualificationSummaryInfo.fullText"
                                name="qualificationSummaryInfo.fullText"
                                className={formik.touched.qualificationSummaryInfo && formik.touched.qualificationSummaryInfo.fullText && formik.errors.fullText ? 'form-control form-control-validation-error' : 'form-control'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={(formik.values.qualificationSummaryInfo && formik.values.qualificationSummaryInfo.fullText) ?? ''}
                            />
                            {formik.touched.qualificationSummaryInfo && formik.touched.qualificationSummaryInfo.fullText && formik.errors.fullText ? (
                                <div>{formik.errors.fullText}</div>
                            ) : null}
                </div>
                    </div>
                </div>
            </div>
       </>
    );
};

export default Resume;