import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { SessionContext } from '../../../Context/Session/SessionContext';
import { useFormik } from 'formik';
import useGetProfile from '../../../Hooks/useGetProfile';
import PersonalInformation from '../PersonalInformation/PersonalInformation';
import AcademicDetails from '../AcademicDetails/AcademicDetails';
import Resume from '../Resume/Resume';
import AdditionalQualifications from '../AdditionalQualifications/AdditionalQualifications';
import ResearchInterests from '../ResearchInterests/ResearchInterests';
import Disclaimer from '../Disclaimer/Disclaimer';
import Documents from '../Documents/Documents';
import useSaveProfile from '../../../Hooks/useSaveProfile';
import Feedback from '../Feedback/Feedback';
import { ApplicationStatus } from '../../../Utils/Enums/Enum';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom';

interface IProfileProps { }

const formValid = ({ ...errors }) => {
    let isValid = true;

    Object.values(errors).map(x => {
        if ((typeof x) !== 'object') {
            if (x !== '') {
                isValid = false;
            }
        }
    });

    errors.detailErrors.map((de: any) => {
        Object.values(de).map(x => {
            if (x !== '') {
                isValid = false;
            }
        });
    });

    return isValid;
};

const validate = (values: any) => {
    let detailErrors = [{ institution: '', degreeID: '', fieldOfStudy: '', highestLevelDegree: '', gpa: '', startDateString: '', graduationDateString: '', currentCumelativeGPA: '' }];

    //AcademicDetails
    values.academicDetailInfo.map((item: any, index: number) => {
        if (index > 0) {
            detailErrors.push({ institution: '', degreeID: '', fieldOfStudy: '', highestLevelDegree: '', gpa: '', startDateString: '', graduationDateString: '', currentCumelativeGPA: '' });
        }
    });

    const errors = {
        //Personal Information
        firstName: '', lastName: '', phone: '', citizenshipID: '',
        //Resume
        personalStatement: '', fullText: '',
        //AcademicDetails
        detailErrors: detailErrors
    };

    const noErrors = {};

    //Personal Information
    if (!values.firstName) {
        errors.firstName = 'First Name is required';
    }

    if (!values.lastName) {
        errors.lastName = 'Last Name is required';
    }

    if (!values.phone) {
        errors.phone = 'Phone is required';
    }

    if (values.applicationInfo.citizenshipID === 1 || values.applicationInfo.citizenshipID === '1') {
        errors.citizenshipID = 'Citizenship is required';
    }

    //Resume
    if (!values.qualificationSummaryInfo.personalStatement) {
        errors.personalStatement = 'Personal statement is required';
    }

    if (!values.qualificationSummaryInfo.fullText) {
        errors.fullText = 'Resumé/CV full text is required';
    }

    let highestLevelDegreeCount = values.academicDetailInfo.filter((x: any) => (x.isVisible === true || x.isVisible === 'true') && x.highestLevelDegree === true || x.highestLevelDegree === 'Yes').length;


    //AcademicDetails
    values.academicDetailInfo.map((item: any, index: number) => {
        if (values.academicDetailInfo[index].isVisible === true || values.academicDetailInfo[index].isVisible === 'true') {

            if (!values.academicDetailInfo[index].institution) {
                errors.detailErrors[index].institution = 'College/University is required';
            }

            if (values.academicDetailInfo[index].degreeID === 0 || values.academicDetailInfo[index].degreeID === '0') {
                errors.detailErrors[index].degreeID = 'Degree is required';
            }

            if (!values.academicDetailInfo[index].fieldOfStudy) {
                errors.detailErrors[index].fieldOfStudy = 'Major or Field of Study is required';
            }

            if (highestLevelDegreeCount === 0) {
                errors.detailErrors[index].highestLevelDegree = 'Highest Level Degree is required';
            } else if (highestLevelDegreeCount > 1) {
                errors.detailErrors[index].highestLevelDegree = 'Only one Highest Level Degree is required';
            } else {
                errors.detailErrors[index].highestLevelDegree = '';
            }

           if (!values.academicDetailInfo[index].currentCumelativeGPA) {
                errors.detailErrors[index].currentCumelativeGPA = 'Current Cumulative GPA is required';
            }

            if (!values.academicDetailInfo[index].gpa) {
                errors.detailErrors[index].gpa = 'GPA Scale is required';
            }

            //console.log(values.academicDetailInfo[index].graduationDateString);
            if (values.academicDetailInfo[index].graduationDateString === '12/31/9999') {
                errors.detailErrors[index].graduationDateString = 'Graduation Date/Anticipated Date is required';
            }

            if (!values.academicDetailInfo[index].graduationDateString) {
                errors.detailErrors[index].graduationDateString = 'Graduation Date/Anticipated Date is required';
            }

            if (values.academicDetailInfo[index].startDateString) {

                if (values.academicDetailInfo[index].graduationDateString) {
                    const sDate = new Date(values.academicDetailInfo[index].startDateString);
                    const gDate = new Date(values.academicDetailInfo[index].graduationDateString);

                    if (sDate > gDate) {
                        errors.detailErrors[index].startDateString = 'Start Date cannot be later than the Graduation Date/Anticipated Date';
                    }
                }
            }
        } else {
            errors.detailErrors[index].institution = '';
            errors.detailErrors[index].degreeID = '';
            errors.detailErrors[index].fieldOfStudy = '';
            errors.detailErrors[index].highestLevelDegree = '';
            errors.detailErrors[index].currentCumelativeGPA = '';
            errors.detailErrors[index].gpa = '';
            errors.detailErrors[index].startDateString = '';
            errors.detailErrors[index].graduationDateString = '';
        }
    });

    const isValid = formValid(errors);
    console.log(isValid, errors);
    return isValid ? noErrors : errors;
};

const Profile: React.FunctionComponent<IProfileProps> = (props) => {
    const toast = useRef<Toast>(null);
    let navigate = useNavigate();

    const [saveProfile, isSendingProfile] = useSaveProfile();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { session } = useContext(SessionContext);
    //console.log(session, session.applicantID, session.applicationID);
    const data = useGetProfile(session.applicantID ?? 0, session.applicationID ?? 0);
    //console.log(data);

    const formik = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validate,
        onSubmit: values => {
            //alert(JSON.stringify(values, null, 2));

            //set the status to NEW
            formik.values.applicationInfo.statusID = ApplicationStatus.New;
            saveProfile(values, toast, true);

            //console.log(values);
            //saveApplicant(values, toast);
        },
    });
    //console.log(formik.values);
    //console.log(formik);

    //    const [applicant, setApplicant] = useState({ formik.values } as Applicant);

    //console.log(formik.values.applicationInfo != null ? formik.values.applicationInfo : formik.values);

    const submitClick = () => {
        setIsSubmitted(true);

        if (!formik.isValid) {
            toast.current?.show({ severity: 'warn', detail: 'Your application needs information in the highlighted required fields', life: 3000 });
        }
    }

    const saveHandler = (e: any) => {
        e.preventDefault();

       saveProfile(formik.values, toast, false);
    }

    const accept = () => {
        navigate('/dashboard', { replace: true });
    }

    const cancelHandler = (e: any) => {
        e.preventDefault();

        confirmDialog({
            message: 'Unsaved changes will be lost, do you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept
        });

    }

    return (
        <>
            <Toast ref={toast} />

            <div id="content" className="container-fluid content div-footer-padding" role="main">
                {isSubmitted && !formik.isValid && (
                    <>
                        <br />
                        <div className="alert alert-warning" role="alert">
                            Your application needs information in the highlighted required fields
                        </div>
                    </>
                )}
                <h2 className="mt-4">My Profile</h2>
                <div className="account-form box-line">
                    <form onSubmit={formik.handleSubmit}>
                        <PersonalInformation formik={formik} />
                        <Documents formik={formik}  />
                        {formik.values.academicDetailInfo && (<AcademicDetails formik={formik} />)}


                        <Resume formik={formik} />
                        <AdditionalQualifications formik={formik} />
                        {formik.values.applicationResearchInterestListInfo && (<ResearchInterests formik={formik} toast={toast} />)}

                        {formik.values.surveyAnswerInfo && (<Feedback formik={formik} />)}

                        <Disclaimer />

                        <div className="row justify-content-end">
                            <button className="btn btn-darkGray mr-2" type="button" onClick={cancelHandler} data-toggle="tooltip" data-placement="top" title="Cancel">Cancel</button>
                            <button className="btn btn-blue mr-2" disabled={isSendingProfile} onClick={saveHandler} type="button">Save</button>
                            <button className="btn btn-primary mr-2" disabled={isSendingProfile} onClick={submitClick} type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Profile;