import { SessionContext } from './../Context/Session/SessionContext';
import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import http from './useHttpClient';
import useGetApiUrls from './useGetApiUrls';
import { Toast } from 'primereact/toast';

const useDeleteDocument = (): [(documentID: number, toast: React.RefObject<Toast>) => void, boolean, number] => {
    const { session } = useContext(SessionContext);
    const { getDeleteDocumentUrl } = useGetApiUrls();
    const [isSending, setIsSending] = useState(false);
    const [deleteDocumentID, setDeleteDocumentID] = useState(0);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const deleteDocument = useCallback(
        (documentID: number, toast: React.RefObject<Toast>) => {

            http(session.apiToken)
                .post(`${getDeleteDocumentUrl}/${documentID}`)
                .then((response) => {
                    setDeleteDocumentID(documentID);
                    toast.current?.show({ severity: 'success', detail: 'Document was removed successfully', life: 3000 });
                })
                .catch((c) => {
                    console.log(c);
                    toast.current?.show({ severity: 'error', detail: 'There was a problem removing your document', life: 3000 });
                })
                .finally(() => {
                    //make sending flag off if component is not unmounted
                    if (isMounted.current) {
                        setIsSending(false);
                    }
                });
        }, [isSending]
    );

    return [deleteDocument, isSending, deleteDocumentID];
};

export default useDeleteDocument;
