import * as React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import { BlockUI } from 'primereact/blockui';

interface IBackDropProps {
    open: boolean
}

const BackDrop: React.FunctionComponent<IBackDropProps> = ({ open }) => {
    return (
        <>
            <BlockUI blocked={open} fullScreen template={<ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />} >
            </BlockUI>
        </>

    );
};

export default BackDrop;