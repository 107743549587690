import * as React from 'react';
import ReactHighlighter from 'react-highlight-words';

interface IHighlighterProps {
    text?: string;
}

const Highlighter: React.FunctionComponent<IHighlighterProps> = ({ text }) => {

    return text ? (
        <ReactHighlighter
            textToHighlight={text}
            activeClassName="highlighter"
            autoEscape={true}
            searchWords={[""]}
        />
    ) : (
        <>{text}</>
    );
};

export default Highlighter;