import http from './useHttpClient';
import Session from '../Entities/Session';

const useDownloadAllApplicationDocuments = (downloadAllApplicantUrl: string, session: Session): boolean => {

    const fileName = session.displayName ?? '';
    const applicationID = session.applicationID;

    http(session.apiToken)
        .get(`${downloadAllApplicantUrl}/${applicationID}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer',
        })
        .then((res) => {

            const a = document.createElement('a');
            a.download = `${fileName}.zip`;
            a.href = URL.createObjectURL(new Blob([res.data]));
            console.log(a.href);
            a.addEventListener('click', (e) => {
                setTimeout(() => URL.revokeObjectURL(a.href), 10 * 1000);
            });
            a.click();
            //const url = window.URL.createObjectURL(new Blob([res.data]));
            //const link = document.createElement('a');
            //link.href = url;
            //link.setAttribute('download', `${fileName}.zip`);
            //document.body.appendChild(link);
            //link.click();
        })
        .catch((c) => { });

    return true;
};

export default useDownloadAllApplicationDocuments;