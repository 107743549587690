import { useEffect, useState } from 'react';
import VersionInformationInfo from '../Entities/VersionInformationInfo';
import http from './useHttpClient';

const useGetVersionInformation = (): [VersionInformationInfo] => {
    const [versionInformationInfo, setVersionInformationInfo] = useState<VersionInformationInfo>({} as VersionInformationInfo)

    useEffect(() => {
        http('')
            .post('/api/data/GetVersionInformation', {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((c) => {
                if (c.data !== null) {
                    setVersionInformationInfo(c.data);
                    return;
                }
                setVersionInformationInfo({} as VersionInformationInfo);
            })
            .catch((c) => {
                setVersionInformationInfo({} as VersionInformationInfo);
            });
        setVersionInformationInfo({} as VersionInformationInfo);
    }, []);
    return [versionInformationInfo];
};

export default useGetVersionInformation;