import { useCallback, useContext } from 'react';
import http from './useHttpClient';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from '../Context/Session/SessionContext';

const useLogout = (): () => void => {
    const navigate = useNavigate();

    const { session, setSessionLogout } = useContext(SessionContext);

    const onLogout = useCallback(() => {
        const idToken = localStorage.getItem('pdsExternalIdToken');

        localStorage.removeItem('pdsExternalIdToken');
        localStorage.removeItem('pdsExtTokenCreation');
        localStorage.removeItem('pdsExternalCurrentUser');
        localStorage.removeItem('pdsExternalIdTokenHash');

        let body = { accTk: '', idToken: idToken, refreshToken: '', reserved: '' };

        http('')
            .post('/api/otp/logoutOtp', body)
            .then(c => {
                setSessionLogout(session);

                window.location = c.data;
            })
            .catch((c) => { })
    }, [])

    return onLogout;

};

export default useLogout;