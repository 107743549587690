import * as React from 'react';
import { useContext } from 'react';
import { SessionContext } from '../../Context/Session/SessionContext';
import useLogout from '../../Hooks/useLogout';

import logo_nih_mark from '../../Shared/Style/images/logo-nih-mark.svg'
import logo_nih_niaid_text_white from '../../Shared/Style/images/logo-nih-niaid-text-white.svg'

interface IHeaderProps {
}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
    const { session } = useContext(SessionContext);

    const logout = useLogout();

    const onLogout = (e: any) => {
        logout();
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <div className='top-header'>
                <div className='header-container'>
                    <div className='container-fluid'>
                        <div className='nih-details'>
                            <a
                                href='https://www.niaid.nih.gov/'
                                rel='noreferrer'
                                target='_blank'
                                className='logo-nih'
                                aria-label='logo'
                            >
                                <img src={logo_nih_mark} className='logo-nih-mark' alt='National Institutes of Health (NIH) Logo' />
                                <img src={logo_nih_niaid_text_white} className='logo-nih-niaid-text' alt='National Institute of Allergy and Infectious Diseases' />
                            </a>
                            <div className='application-details'>
                                <h1>PostDoc Application System</h1>
                            </div>
                        </div>
                    </div>
                </div>
                {session.isAuthenticated && (
                    <div className='user-block'>
                        <div className='user-status'>
                            <span className='user-name'>{session.displayName}</span>
                        </div>
                        <a
                            onClick={(e) => {
                                onLogout(e);
                            }}
                            className='btn btn-slanted'
                        >
                            <span>Logout</span>
                        </a>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Header;