(function () {
    'use strict';

    var analytics = {
        trackingTimeStamps: {}
    };

    analytics.setTimestamp = function (id) {
        analytics.trackingTimeStamps[id] = new Date().getTime();
    };

    analytics.getTimestamp = function (id) {
        return analytics.trackingTimeStamps[id];
    };

    analytics.initGoogleAnalytics = function (key) {
        if (key) {
            analytics.gaKey = key;

            // Disable linting for 3rd party tracking codes
            /* eslint-disable */
            /* jshint ignore:start */
            // jscs:disable

            (function (i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments)
                }, i[r].l = 1 * new Date(); a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
            })(window, document, 'script', 'https://www.googletagmanager.com/gtag/js?id=' + analytics.gaKey, 'ga');

            window.dataLayer = window.dataLayer || [];
            analytics.gtag = function () { dataLayer.push(arguments); }
            analytics.gtag('js', new Date());
            analytics.gtag('config', analytics.gaKey);

            // jscs:enable
            /* jshint ignore:end */
            /* eslint-enable */
        } else {
            console.error('Please pass a valid Google Analytics key when using the "initGoogleAnalytics" function.');
        }
    };

    analytics.initAnalytics = function (key) {
        analytics.initGoogleAnalytics(key);
    };

    // track current URL as page view, especially useful for SPAs.
    analytics.trackPageView = function () {
        analytics.gtag('event', 'page_view', {
            page_title: document.title,
            page_location: window.location.origin,
            page_path: window.location.pathname,
            send_to: analytics.gaKey
        });
    };

    // trackPage is depreciated, please use trackPageView
    analytics.trackPage = function () {
        analytics.trackPageView();
    };

    analytics.trackEvent = function (options) {
        if (options.category && options.action && options.label) {
            analytics.gtag('event', options.action, {
                'event_category': options.category,
                'event_label': options.label,
                'value': options.value
            });
        }
    };

    analytics.trackTiming = function (options) {
        if (options.category && options.name && options.timeStamp) {
            analytics.gtag('event', 'timing_complete', {
                'name': options.name || 'load',
                'value': new Date().getTime() - analytics.getTimestamp(options.timeStamp),
                'event_category': options.category,
                'label': options.label
            });
        }
    };

    analytics.trackError = function (options) {
        if (options.description && options.fatal) {
            analytics.gtag('event', 'exception', {
                'description': options.description,
                'fatal': options.fatal || false // set to true if the error is fatal
            });
        }
    };

    window.analytics = analytics;

    analytics.initAnalytics('G-2RCJ10CV1X');
}());