import { SessionContext } from './../Context/Session/SessionContext';
import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import http from './useHttpClient';
import useGetApiUrls from './useGetApiUrls';
import Applicant from '../Entities/Applicant';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

const useSaveProfile = (): [(applicant: Applicant, toast: React.RefObject<Toast>, isSubmit: boolean) => void, boolean] => {
    let navigate = useNavigate();

    const { getSaveProfileUrl } = useGetApiUrls();
    const { session } = useContext(SessionContext);
    const [isSending, setIsSending] = useState(false);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const saveProfile = useCallback(
        (applicant: Applicant, toast: React.RefObject<Toast>, isSubmit: boolean) => {
            //console.log('Save Profile', applicant);

            //fix the highestLevelDegree, gpa and currentCumelativeGPA
            applicant.academicDetailInfo.map((x: any, index: number) => {
                applicant.academicDetailInfo[index].highestLevelDegree = (x.highestLevelDegree === true || x.highestLevelDegree === 'Yes');

                let gpaNumber = parseFloat(applicant.academicDetailInfo[index].gpa.toString());
                applicant.academicDetailInfo[index].gpa = isNaN(gpaNumber) ? 0 : gpaNumber;

                gpaNumber = parseFloat(applicant.academicDetailInfo[index].currentCumelativeGPA.toString());
                applicant.academicDetailInfo[index].currentCumelativeGPA = isNaN(gpaNumber) ? 0 : gpaNumber;
            });

            http(session.apiToken)
                .post(getSaveProfileUrl, applicant, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    // TODO: add toast save is successful
                    if (isSubmit) {
                        session.isSubmitted = true;

                        navigate('/dashboard', { replace: true });
                    }
                    else {
                        toast.current?.show({ severity: 'success', detail: 'Application was saved successfully', life: 3000 });
                    }
                })
                .catch((c) => {
                    // TODO: add toast save is NOT successful
                    console.log(c);
                    if (isSubmit) {
                        toast.current?.show({ severity: 'error', detail: 'There was a problem submitting your Application', life: 3000 });
                    }
                    else {
                        toast.current?.show({ severity: 'error', detail: 'There was a problem saving your Application', life: 3000 });
                    }
                })
                .finally(() => {
                    //make sending flag off if component is not unmounted
                    if (isMounted.current) {
                        setIsSending(false);
                    }
                });
        }, [isSending]
    );


    return [saveProfile, isSending];
};

export default useSaveProfile;