import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../../Context/Session/SessionContext';
import AcademicDetail from '../../../Entities/AcademicDetail';
import useGetDegree from '../../../Hooks/useGetDegree';
import { getDateFormatMMddYYY } from '../../../Utils/dateFormats';
import { DegreeType } from '../../../Utils/Enums/Enum';

interface IAcademicDetailsViewProps {
    academicDetailInfo: AcademicDetail[];
}

const AcademicDetailsView: React.FunctionComponent<IAcademicDetailsViewProps> = ({ academicDetailInfo }) => {
    const { session } = useContext(SessionContext);
    const [details, setDetails] = useState(academicDetailInfo);

    //console.log(academicDetailInfo);

    const [degrees] = useGetDegree();

    useEffect(() => {
        setDetails(academicDetailInfo);
        if (degrees && degrees.length > 0 && academicDetailInfo) {
            let otherID = DegreeType.Other;

            academicDetailInfo.map((x: any) => {
                const degreeInfo = degrees.filter((d: any) => d.degreeID === x.degreeID);
               if (degreeInfo.length > 0) {
                  x.degreeName = x.degreeID !== otherID ? degreeInfo[0].name : x.degreeName;
                }
            });
        }
    }, [academicDetailInfo])

    return (
        <>
            <div className="detail-section education-section">
                <div className="section-head">
                    <div className="section-circle">
                        <div className="section-icon"></div>
                    </div>
                    <div className="section-title">Education</div>
                    <div className="section-head-bar"></div>
                </div>

                {details && details.map((academicDetail) => (
                    <div className="additional-section" key={academicDetail.academicDetailID.toString()} >
                        <div className="row">
                            <div className="col-xl-3">
                                <p className="title">College/University</p>
                                <p className="description">
                                    {academicDetail.institution}
                                </p>
                            </div>
                            <div className="col-xl-3">
                                <p className="title">Degree</p>
                                <p className="description">{academicDetail.degreeName}</p>
                            </div>
                            <div className="col-xl-3">
                                <p className="title">Major or Field of Study</p>
                                <p className="description">{academicDetail.fieldOfStudy}</p>
                            </div>
                            <div className="col-xl-3">
                                <p className="title">Highest Level Degree</p>
                                <p className="description">
                                    {academicDetail.highestLevelDegree === true ? 'Yes' : 'No'}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <p className="title">
                                    Thesis/Dissertation (optional)
                                </p>
                                <p className="description">{academicDetail.thesis}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3">
                                <p className="title">Current Cumulative GPA</p>
                                <p className="description">
                                    {academicDetail.currentCumelativeGPA}
                                </p>
                            </div>
                            <div className="col-xl-3">
                                <p className="title">GPA Scale (Maximum possible)</p>
                                <p className="description">{academicDetail.gpa}</p>
                            </div>
                            <div className="col-xl-3">
                                <p className="title">Start Date (optional)</p>
                                <p className="description">{getDateFormatMMddYYY(academicDetail.startDate)}</p>
                            </div>
                            <div className="col-xl-3">
                                <p className="title">
                                    Graduation Date/Anticipated Date
                                </p>
                                <p className="description">{getDateFormatMMddYYY(academicDetail.graduationDate)}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default AcademicDetailsView;