import * as React from 'react';
import Applicant from '../../../Entities/Applicant';
import arrowBanner from '../../../Shared/Style/images/banner-arrow.404b3d60.svg';
import { DocumentType } from '../../../Utils/Enums/Enum';
import { Tooltip } from 'primereact/tooltip';

interface IDocumentsViewProps {
    applicant: Applicant;
    DownloadAllApplicantDocuments: () => void;
}

const DocumentsView: React.FunctionComponent<IDocumentsViewProps> = ({ applicant, DownloadAllApplicantDocuments}) => {
    return (
        <>
            {applicant.documentInfo && applicant.documentInfo.length > 0 &&
                <div className="mt-3">
                    <div className="section-head d-flex justify-content-between align-items-center">
                        <div className="section-title">Documents</div>
                        <button
                            onClick={DownloadAllApplicantDocuments}
                            className="btn btn-round icon-download mb-3"
                            type="button"
                        >
                            <span>Download All</span>
                        </button>
                    </div>
                    {applicant.documentInfo?.map((documentInfo: any) => (
                        <div key={documentInfo.documentID}>
                            {documentInfo &&
                                documentInfo.documentTypeID === DocumentType.Resume && (
                                    <button className="btn-guide btn-guide-green">
                                        <div className="document-details d-flex justify-content-between">
                                            <div className="document-name">
                                                <span className="document-title">Resume</span>
                                                <span className="document-fullname">
                                                <Tooltip target=".fileShortDisplayNameResume" mouseTrack mouseTrackLeft={10} />
                                                <p
                                                    className="fileShortDisplayNameResume"
                                                    data-pr-tooltip={documentInfo.fileName}
                                                >
                                                    {documentInfo.fileShortDisplayName}
                                                </p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="chevron-right">
                                            <img src={arrowBanner} alt="Document" />
                                        </div>
                                    </button>
                                )}

                            {documentInfo &&
                                documentInfo.documentTypeID === DocumentType.CoverLetter && (
                                    <button className="btn-guide btn-guide-blue mt-3">
                                        <div className="document-details d-flex justify-content-between">
                                            <div className="document-name">
                                                <span className="document-title">
                                                    Cover Letter
                                                </span>
                                                <span className="document-fullname">
                                                <Tooltip target=".fileShortDisplayNameCoverLetter" mouseTrack mouseTrackLeft={10} />
                                                <p
                                                    className="fileShortDisplayNameCoverLetter"
                                                    data-pr-tooltip={documentInfo.fileName}
                                                >
                                                    {documentInfo.fileShortDisplayName}
                                                </p>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="chevron-right">
                                            <img src={arrowBanner} alt="Document" />
                                        </div>
                                    </button>
                                )}
                        </div>
                    ))}

                    <div className="detail-section" style={{ marginTop: 15 }}>
                        <div className="section-title">Aditional Documents</div>
                        {applicant.documentInfo?.map((documentInfo: any) => (
                            <div key={documentInfo.documentID}>
                                {documentInfo &&
                                    documentInfo.documentTypeID === DocumentType.Additional && (
                                        <p>{documentInfo.fileName}</p>
                                    )}
                            </div>
                        ))}
                    </div>
                </div>
            }
      </>
    );
};

export default DocumentsView;