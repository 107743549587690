import * as React from 'react';
import { useState, useEffect } from 'react';
import { Rating } from 'primereact/rating';

interface IFeedbackProps {
    formik: any;
}

const Feedback: React.FunctionComponent<IFeedbackProps> = ({ formik }) => {
    const [answer2, setAnswer2] = useState<any>(null);
    const [answer3, setAnswer3] = useState<any>(null);
    const [other, setOther] = useState(true);

    useEffect(() => {
        setAnswer2(parseInt(formik.values.surveyAnswerInfo[2].answerText || 0));
        setAnswer3(parseInt(formik.values.surveyAnswerInfo[3].answerText || 0));

        setOther(formik.values.surveyAnswerInfo[1].answerText === '');
    }, []);

    useEffect(() => {
        if (answer2 !== null) {
            formik.values.surveyAnswerInfo[2].answerText = answer2.toString();
        } else {
            formik.values.surveyAnswerInfo[2].answerText = '0';
        }
    }, [answer2])

    useEffect(() => {
        if (answer3 !== null) {
            formik.values.surveyAnswerInfo[3].answerText = answer3.toString();
        } else {
            formik.values.surveyAnswerInfo[3].answerText = '0';
        }
    }, [answer3])

    useEffect(() => {
        if (formik.values.surveyAnswerInfo[0].answerText === 'Other') {
            setOther(false);
        } else {
            formik.values.surveyAnswerInfo[1].answerText = '';
            setOther(true);
    }
    }, [formik.values.surveyAnswerInfo[0].answerText])

    return (
        <>
            <h5>Feedback</h5>
            <div className="form-section">
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="question1">{formik.values.surveyAnswerInfo[0].questionText}</label>
                            <select
                                className="form-control"
                                id="surveyAnswerInfo[0].answerText"
                                name="surveyAnswerInfo[0].answerText"
                                onChange={formik.handleChange}
                                value={(formik.values.surveyAnswerInfo[0] && formik.values.surveyAnswerInfo[0].answerText) ?? ''}
                            >
                                <option></option>
                                <option>Academic Advisor</option>
                                <option>Career Counselor</option>
                                <option>University faculty member</option>
                                <option>NIH faculty/staff member</option>
                                <option>Friend/family member</option>
                                <option>Conference</option>
                                <option>Web search</option>
                                <option>LinkedIn/Job posting site</option>
                                <option>OITE Postdoc Positions website</option>
                                <option>NIAID website</option>
                                <option>NIH Careers website</option>
                                <option>University job board/site</option>
                                <option>Other</option>
                            </select>
                            <br />
                            <label htmlFor="question1">{formik.values.surveyAnswerInfo[1].questionText}</label>
                            <input type="text"
                                id="surveyAnswerInfo[1].answerText"
                                name="surveyAnswerInfo[1].answerText"
                                className="form-control"
                                onChange={formik.handleChange}
                                value={(formik.values.surveyAnswerInfo[1] && formik.values.surveyAnswerInfo[1].answerText) ?? ''}
                                disabled={other}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="question2">{formik.values.surveyAnswerInfo[2].questionText}</label>
                            <Rating value={answer2} onChange={(e) => setAnswer2(e.value)} style={{ width: '200px' }} />(5 - Very Easy)
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="question3">{formik.values.surveyAnswerInfo[3].questionText}</label>
                            <Rating
                                value={answer3}
                                onChange={(e) => setAnswer3(e.value)}
                            />(5- Very Satisfied)
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="question4">{formik.values.surveyAnswerInfo[4].questionText}</label>
                            <textarea rows={3}
                                id="surveyAnswerInfo[4].answerText"
                                name="surveyAnswerInfo[4].answerText"
                                className="form-control"
                                onChange={formik.handleChange}
                                value={(formik.values.surveyAnswerInfo[4] && formik.values.surveyAnswerInfo[4].answerText) ?? ''}
                                maxLength={500}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Feedback;