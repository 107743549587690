import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../../Context/Session/SessionContext';
import { useNavigate } from 'react-router-dom';
import AdditionalQualificationsView from '../AdditionalQualifications/AdditionalQualificationsView';
import useGetProfile from '../../../Hooks/useGetProfile';
import AcademicDetailsView from '../AcademicDetails/AcademicDetailsView';
import useDownloadAllApplicationDocuments from '../../../Hooks/useDownloadAllApplicationDocuments';
import DocumentsView from '../Documents/DocumentsView';
import useGetApiUrls from '../../../Hooks/useGetApiUrls';
import { Tooltip } from 'primereact/tooltip';

interface IProfileViewProps { }

const ProfileView: React.FunctionComponent<IProfileViewProps> = (props) => {
    const navigate = useNavigate();
    const { session } = useContext(SessionContext);
    const { downloadAllApplicantUrl } = useGetApiUrls();
    //console.log('downloadAllApplicantUrl', downloadAllApplicantUrl);

    const applicant = useGetProfile(session.applicantID ?? 0, session.applicationID ?? 0);

    const citizenshipName = session.citizenshipName;
    const researchInterests = session.researchInterests;

    const returnClick = (e: any) => {
        navigate('/dashboard', { replace: true });
    }

    const DownloadAllApplicantDocuments = () => {
        useDownloadAllApplicationDocuments(downloadAllApplicantUrl ?? '', session);
    };

    return (
        <>
            <div id="content" className="container-fluid content" role="main">
                <section className="applicants">
                    <button className="btn btn-primary ml-3" type="button" onClick={returnClick} data-toggle="tooltip" data-placement="top" title="Return to Dashboard">Return to Dashboard</button>
                    <div id="content" className="container-fluid mt-4" role="main">
                        <div className="row">
                            <div className="col-xl-8 col-lg-12">
                                <AcademicDetailsView academicDetailInfo={applicant.academicDetailInfo} />
                                <AdditionalQualificationsView qualificationSummaryInfo={applicant.qualificationSummaryInfo} />
                            </div>

                            <div className="col-xl-4 col-lg-12">
                                <div className="sidebar-right">
                                    <div className="detail-section">
                                        <div className="section-title">Personal Information</div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <p className="title">First Name</p>
                                                <p className="description">{applicant?.firstName}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p className="title">Middle Name</p>
                                                <p className="description">{applicant?.middleName}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p className="title">Last Name</p>
                                                <p className="description">{applicant?.lastName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <p className="title">Email</p>
                                                <Tooltip target=".emailAddress" mouseTrack mouseTrackLeft={10} />
                                                <p
                                                    className="emailAddress"
                                                    data-pr-tooltip={applicant?.emailAddress}
                                                >
                                                    <p className="description">{applicant?.emailAddress}</p>
                                                </p>
                                           </div>
                                            <div className="col-md-4">
                                                <p className="title">Phone</p>
                                                <p className="description">{applicant?.phone}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p className="title">Citizenship</p>
                                                <Tooltip target=".citizenshipName" mouseTrack mouseTrackLeft={10} />
                                                <p
                                                    className="citizenshipName"
                                                    data-pr-tooltip={citizenshipName}
                                                >
                                                    <p className="description">{citizenshipName}</p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <DocumentsView applicant={applicant} DownloadAllApplicantDocuments={DownloadAllApplicantDocuments} />

                                    <div className="detail-section mt-3">
                                        <div className="section-title">Research Areas</div>
                                        <p>{researchInterests}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default ProfileView;