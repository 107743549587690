import { useContext, useEffect, useState } from 'react';
import http from './useHttpClient';
import { SessionContext } from './../Context/Session/SessionContext';
import Applicant from '../Entities/Applicant';
import useGetApiUrls from './useGetApiUrls';
import AcademicDetail from '../Entities/AcademicDetail';


const useGetProfile = (applicantID: number, applicationID: number): Applicant => {
    const { session } = useContext(SessionContext);
    const { getProfileUrl } = useGetApiUrls();

    const [applicant, setApplicant] = useState<Applicant>({} as Applicant);

    useEffect(() => {
        http(session.apiToken).post(`${getProfileUrl}/${applicantID}/${applicationID}`)
            .then(response => {

                //add disabled property to use with remove button on UI
                let academicDetail: AcademicDetail[] = [];
                response.data.academicDetailInfo.map((x: any, index: number) => {
                    x.disabled = index === 0;
                    academicDetail.push(x);
                });
                response.data.academicDetailInfo = academicDetail;

                setApplicant(response.data);
            })
            .catch((ex) => {
                console.log(ex);
            })
        !applicantID && setApplicant({} as Applicant);
    }, [applicantID]);

    return applicant;
}

export default useGetProfile;