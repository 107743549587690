import * as React from "react";
import { useCallback, useContext, useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { ConfirmDialog } from "primereact/confirmdialog";

import useLogout from "../../Hooks/useLogout";
import { SessionContext } from "../../Context/Session/SessionContext";
import { Button } from "primereact/button";

interface IIdleTimeOutProps { }

const IdleTimeOut: React.FunctionComponent<IIdleTimeOutProps> = ({
    children,
}) => {
    const { session } = useContext(SessionContext);
    const logoutTimer = useRef<any>(null);
    const idleTimer = useRef<any>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const Logout = useCallback(useLogout(), []);

    const onIdle = () => {
        setVisible(true);
        logoutTimer.current = setTimeout(() => {
            Logout();
        }, 1000 * 60 * 10);
    };

    const handleStayLoggedIn = () => {
        if (logoutTimer && logoutTimer.current) {
            clearTimeout(logoutTimer.current);
            logoutTimer.current = null;
        }
        idleTimer.current.reset();
        setVisible(false);
    };

    const footer = () => {
        return <Button onClick={handleStayLoggedIn} icon="pi pi-check" label="Stay Logged In"></Button>
    }
    return (
        <>
            <IdleTimer
                ref={(ref: any) => {
                    idleTimer.current = ref;
                }}
                timeout={((session?.idleTimeOutInMins ?? 60) - 10) * 60 * 1000}
                onIdle={onIdle}
            >
                {children}

            </IdleTimer>
            <ConfirmDialog
                visible={visible}
                onHide={() => setVisible(false)}
                message="Because you have been inactive, your session is about to expire."
                header="Session Expiration Warning"
                icon="pi pi-exclamation-triangle"
                footer={footer()}
                className="whitebackgroud"
            />
        </>
    );
};

export default IdleTimeOut;
