import { createContext, useReducer } from 'react';
import { SESSION_INITIALIZED, SESSION_ESTABLISHED, SESSION_SAVED, SESSION_LOGOUT } from '../../Utils/Constants/Session';
import Session from '../../Entities/Session';
import SessionReducer from './SessionReducer';
import {
    browserName,
    fullBrowserVersion,
    deviceType,
    osName,
    osVersion
} from 'react-device-detect';

const initalSessionState = {
    session: {
        applicantID: 0,
        browser: browserName,
        browser_version: fullBrowserVersion,
        browserId: 0,
        device: deviceType,
        os: osName,
        os_version: osVersion,
        userAgent: '',
        hasApplicants: false,
        isAuthenticated: false
    } as Session,
    setSession: (session: Session) => { },
    setSessionInitialized: (session: Session) => { },
    setSessionSaved: (session: Session) => { },
    setSessionLogout: (session: Session) => { },
};

// create session Context object with initial state
export const SessionContext = createContext(initalSessionState);

type Props = {
    children: JSX.Element,
};

//create global session provider
export const SessionProvider: React.FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(SessionReducer, initalSessionState.session)

    const setSession = (session: Session) => {
        dispatch(
            {
                type: SESSION_ESTABLISHED,
                payload: session
            }
        )
    };

    const setSessionInitialized = (session: Session) => {
        dispatch(
            {
                type: SESSION_INITIALIZED,
                payload: session
            }
        )
    };

    const setSessionSaved = (session: Session) => {
        dispatch(
            {
                type: SESSION_SAVED,
                payload: session
            }
        )
    };

    const setSessionLogout = (session: Session) => {
        dispatch(
            {
                type: SESSION_LOGOUT,
                payload: session
            }
        )
    };

    return <SessionContext.Provider
        value={{
            session: state
            , setSession
            , setSessionInitialized
            , setSessionSaved
            , setSessionLogout
        }}> {children} </SessionContext.Provider>
}