import * as React from 'react';
import Login from '../Common/Login';

interface IUnauthorizedProps {
}

const Unauthorized: React.FunctionComponent<IUnauthorizedProps> = (props) => {

    return (
        <>
            <Login isWelcome={false} />
        </>
    );
};

export default Unauthorized;