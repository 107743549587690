import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import http from './useHttpClient';
import { SessionContext } from './../Context/Session/SessionContext';
import ApplicantInfo from '../Entities/ApplicantInfo';
import useGetApiUrls from './useGetApiUrls';

const useGetApplicantList = (applicantID: number, setLoading: Dispatch<SetStateAction<boolean>>): ApplicantInfo[] => {
    const { session } = useContext(SessionContext);
    const { getApplicantListUrl } = useGetApiUrls();

    const [applicantList, setApplicantList] = useState<ApplicantInfo[]>({} as ApplicantInfo[]);

    useEffect(() => {
        setLoading(true);
        http(session.apiToken).post(`${getApplicantListUrl}/${applicantID}`)
            .then(response => {
                //console.log('useGetApplicantList', response.data);
                setApplicantList(response.data);
                setLoading(false)
            })
            .catch((ex) => {
                setLoading(false)
                console.log(ex);
            })
        !applicantID && setApplicantList({} as ApplicantInfo[]);
    }, [applicantID]);

    return applicantList;
}

export default useGetApplicantList;