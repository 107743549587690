import * as React from 'react';
import VersionInformationInfo from '../../Entities/VersionInformationInfo';
import HHSLogoGray from '../../Shared/Style/images/logo-hhs-gray.svg'
import NIHMarkGray from '../../Shared/Style/images/logo-nih-mark-gray.svg'
import USAGovLogGray from '../../Shared/Style/images/logo-usagov-gray.svg'

interface IFooterProps {
    versionInformationInfo: VersionInformationInfo;
}

const Footer: React.FunctionComponent<IFooterProps> = ({ versionInformationInfo }) => {
    let versionDate = versionInformationInfo && versionInformationInfo.versionDate ? new Date(versionInformationInfo.versionDate).toLocaleDateString() : '';

    return <>
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="footer-logos">
                            <a
                                href="https://www.hhs.gov/"
                                //alt="www.hhs.gov"
                                aria-label="visit HHS.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                            ><img
                                    src={HHSLogoGray}
                                    alt="Logo HHS"
                                    className="logo-footer-hhs" /></a
                            ><a
                                href="https://www.nih.gov/"
                                //alt="www.nih.gov"
                                aria-label="visit NIH.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                            ><img
                                    src={NIHMarkGray}
                                    alt="Logo NIH"
                                    className="logo-footer-nih" /></a
                            ><a
                                href="https://www.usa.gov/"
                                //alt="www.usa.gov"
                                aria-label="visit USA.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                            ><img
                                    src={USAGovLogGray}
                                    alt="Logo HHS"
                                    className="logo-footer-usagov"
                                /></a>
                        </div>
                        <div className="footer-text">
                            <div className="footer-content">
                                <div className="req-links">
                                    <a
                                        href="https://www.niaid.nih.gov"
                                        //alt="visit NIAID.NIH.gov"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >NIAID.NIH.GOV</a
                                    ><a
                                        href="https://www.niaid.nih.gov/node/5228"
                                        //alt="view the No Fear Act"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >No Fear Act</a
                                    ><a
                                        href="https://www.niaid.nih.gov/node/5229"
                                        //alt="view the Privacy Policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Privacy Policy</a
                                    ><a
                                        href="https://www.niaid.nih.gov/node/5225"
                                        //alt={"view the Freedom of Information Act"}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >FOIA</a
                                    ><a
                                        href="https://inside.niaid.nih.gov/communications-media/accessibility-and-reasonable-accommodations"
                                        //alt="view Accessibility Guidelines"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Accessibility</a
                                    ><a
                                        href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html"
                                        //alt="contact us"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Vulnerability Disclosure Policy</a
                                    ><a
                                        href="mailto:NIAIDTraining@nih.gov"
                                        //alt="contact us"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Contact Us</a
                                    >
                                </div>
                            </div>
                            <div className="application-details">


                                <div className="footer-title">
                                    PostDoc Application System
                                </div>
                                <div className="footer-version">
                                    Version: {versionInformationInfo.versionNumber} | Last Release: {versionDate}
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default Footer;