import * as React from 'react';
import WelcomeLanguage from '../../Common/WelcomeLanguage';

interface IDisclaimerProps { }

const Disclaimer: React.FunctionComponent<IDisclaimerProps> = (props) => {

    return (
        <>
            <div className="form-section">
                <div className="row" style={{ padding: '6px' }}>
                    <WelcomeLanguage displayIntro={false} displayInfo={true} />
                </div>
            </div>
        </>
    );
};

export default Disclaimer;