import { useContext } from 'react';
import { SessionContext } from './../Context/Session/SessionContext';

const useGetApiUrls = () => {
    const { session } = useContext(SessionContext);

    //Applicant
    const getApplicantsUrl = `${session.apiUrl}Applicant/GetApplicants`;
    const getResearchUrl = `${session.apiUrl}Applicant/ResearchAreas`;
    const getApplicantListUrl = `${session.apiUrl}Applicant/GetApplicantList`;
    const getProfileUrl = `${session.apiUrl}Applicant/GetProfile`;
    const getAddApplicantUrl = `${session.apiUrl}Applicant/AddProfile`;
    const getSaveProfileUrl = `${session.apiUrl}Applicant/SaveProfile`;
    const getUploadDocumentUrl = `${session.apiUrl}Applicant/UploadDocument`;
    const getUpdateUploadDocumentUrl = `${session.apiUrl}Applicant/UpdateUploadDocument`;
    const getDeleteDocumentUrl = `${session.apiUrl}Applicant/DeleteDocument`;
    const getCopyApplicationUrl = `${session.apiUrl}Applicant/CopyApplication`;
    const downloadAllApplicantUrl = `${session.apiUrl}Applicant/DownloadApplicationDocuments`;

    //Lookup
    const getDegreeUrl = `${session.apiUrl}Lookup/GetDegrees`;
    const getDegreesOtherUrl = `${session.apiUrl}Lookup/GetDegreesOther`;
    const getCitizenshipUrl = `${session.apiUrl}Lookup/GetCitizenships`;
    const getCitizenshipsUSAFirstUrl = `${session.apiUrl}Lookup/GetCitizenshipsUSAFirst`;
    const getResearchInterestsUrl = `${session.apiUrl}Lookup/GetResearchInterests`;


    return {
        //Applicant
        getApplicantsUrl,
        getResearchUrl,
        getApplicantListUrl,
        getSaveProfileUrl,
        getProfileUrl,
        getAddApplicantUrl,
        getUploadDocumentUrl,
        getUpdateUploadDocumentUrl,
        getDeleteDocumentUrl,
        getCopyApplicationUrl,
        downloadAllApplicantUrl,

        //Lookup
        getDegreeUrl,
        getDegreesOtherUrl,
        getCitizenshipUrl,
        getCitizenshipsUSAFirstUrl,
        getResearchInterestsUrl,
   };
};

export default useGetApiUrls;