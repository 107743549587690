import { SessionContext } from './../Context/Session/SessionContext';
import { useEffect, useState, useContext, Dispatch, SetStateAction } from 'react';
import http from './useHttpClient';
import { useLocation, useParams } from 'react-router-dom';
import Session from '../Entities/Session';

const getHashFromUrl = (str: any) => {
    const query = str.substr(1);
    const result = {} as any;

    query.split('&').forEach(function (part: any) {
        const item = part.split('=');
        result[item[0]] = decodeURIComponent(item[1]);
    });

    return result;
};

const useGetOTPSession = (): [boolean, boolean, boolean] => {
    const { session, setSession } = useContext(SessionContext);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isUnauthorized, setIsUnauthorized] = useState(false);

    const location = useLocation();
    const params = useParams();
    let body = {} as any;
    let canAuthenticate = false;

    if (location.hash) {
        const hash = getHashFromUrl(location.hash);
        body = { accTk: hash.access_token, idToken: hash.id_token, refreshToken: '', reserved: '' };
        canAuthenticate = true;
    }

    console.log('canAuthenticate', canAuthenticate);
    if (canAuthenticate) {
        //console.log('Logged In params', location.hash);
        //console.log('body', body);
    }

    useEffect(() => {
        canAuthenticate &&
            http('')
                .post('/api/otp/authenticate', body)
                .then((c) => {
                    //console.log('c.data', c.data);
                    //console.log('Logged In params', location.hash);

                    const { otpIdToken, otpUser } = c.data;
                    var otpUserJson = JSON.stringify(otpUser);

                    localStorage.setItem('pdsExternalIdToken', otpIdToken);
                    localStorage.setItem('pdsExternalCurrentUser', otpUserJson);

                    let newDate = new Date();
                    let date = newDate.getTime();
                    localStorage.setItem('pdsExtTokenCreation', JSON.stringify(date));
                    localStorage.setItem('pdsExternalIdTokenHash', location.hash);

                    if (c.data.apiSession.apiToken !== '') {
                        setSession(JSON.parse(c.data.apiSession) as Session);

                        window.history.replaceState({}, document.title, '/');

                        setAuthenticated(true);
                        setLoading(false);
                        return;
                    }

                    setAuthenticated(false);
                    setLoading(false);
                })
                .catch((c) => {
                    console.log('FAILED-catch', c);
                    setAuthenticated(false);
                    setLoading(false);
                    setIsUnauthorized(false);
                });
        setLoading(false);
    }, [])

    return [isAuthenticated, isLoading, isUnauthorized];
};

export default useGetOTPSession;