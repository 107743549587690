import * as React from 'react';
import QualificationSummary from '../../../Entities/QualificationSummary';

interface IAdditionalQualificationsViewProps {
    qualificationSummaryInfo: QualificationSummary;
}

const AdditionalQualificationsView: React.FunctionComponent<IAdditionalQualificationsViewProps> = ({ qualificationSummaryInfo }) => {

    return (
        <>
            <div className="detail-section publications-section">
                <div className="section-head">
                    <div className="section-circle">
                        <div className="section-icon"></div>
                    </div>
                    <div className="section-title">Personal Statement </div>
                    <div className="section-head-bar"></div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <p>{qualificationSummaryInfo?.personalStatement}</p>
                    </div>
                </div>
            </div>

            <div className="detail-section education-section">
                <div className="section-head">
                    <div className="section-circle">
                        <div className="section-icon"></div>
                    </div>
                    <div className="section-title">Resumé/CV Full Text </div>
                    <div className="section-head-bar"></div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <p>{qualificationSummaryInfo?.fullText}</p>
                    </div>
                </div>
            </div>

           <div className="detail-section certifications-section">
                <div className="section-head">
                    <div className="section-circle">
                        <div className="section-icon"></div>
                    </div>
                    <div className="section-title">
                        Certifications or Additional Trainings
                    </div>
                    <div className="section-head-bar"></div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <p>{qualificationSummaryInfo?.certifications}</p>
                    </div>
                </div>
            </div>

            <div className="detail-section research-section">
                <div className="section-head">
                    <div className="section-circle">
                        <div className="section-icon"></div>
                    </div>
                    <div className="section-title">Research Experiences</div>
                    <div className="section-head-bar"></div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <p>{qualificationSummaryInfo?.researchExperience}</p>
                    </div>
                </div>
            </div>

            <div className="detail-section publications-section">
                <div className="section-head">
                    <div className="section-circle">
                        <div className="section-icon"></div>
                    </div>
                    <div className="section-title">Publications</div>
                    <div className="section-head-bar"></div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <p>{qualificationSummaryInfo?.publications}</p>
                    </div>
                </div>
            </div>

            <div className="detail-section presentations-section">
                <div className="section-head">
                    <div className="section-circle">
                        <div className="section-icon"></div>
                    </div>
                    <div className="section-title">Presentations</div>
                    <div className="section-head-bar"></div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <p>{qualificationSummaryInfo?.presentations}</p>
                    </div>
                </div>
            </div>

            <div className="detail-section awards-section">
                <div className="section-head">
                    <div className="section-circle">
                        <div className="section-icon"></div>
                    </div>
                    <div className="section-title">Awards and Honors</div>
                    <div className="section-head-bar"></div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <p>{qualificationSummaryInfo?.awardsAndHonors}</p>
                    </div>
                </div>
            </div>

            <div className="detail-section leadership-section">
                <div className="section-head">
                    <div className="section-circle">
                        <div className="section-icon"></div>
                    </div>
                    <div className="section-title">
                        Leadership and Outreach Experience
                    </div>
                    <div className="section-head-bar"></div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <p>{qualificationSummaryInfo?.leaderShipOutreachExperience}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdditionalQualificationsView;